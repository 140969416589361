import { createReducer } from '@reduxjs/toolkit';

// Initial state
const initialState = {
    loading: false,
    apiNames: null,
    error: null,
    message: null,
};

export const sequencingReducer = createReducer(initialState, (builder) => {

    builder
    .addCase('requestNamesRequest', (state, action) => {
        state.loading = true;
    })
    .addCase('requestNamesSuccess', (state, action) => {
        state.loading = false;
        state.apiNames = action.payload;
    })
    .addCase('requestNamesFailure', (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
})
