import EditIcon from "../../../icons/edit-icon.svg";
import { useState } from "react";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const BaseUrl = ({source, sources, setSources}) => {
    const [edit, setEdit] = useState(source?.source ? false : true);
    const [newSource, setNewSource] = useState(source?.source ?? "");
    const [url, setUrl] = useState(source?.baseUrl ?? "");

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setSnackbarOpen(false);
      };

    const isValidBaseUrl = (url) => {
        try {
          const parsedUrl = new URL(url);
    
          // Ensure that the URL includes a protocol and hostname
          return (
            ["http:", "https:"].includes(parsedUrl.protocol) && !!parsedUrl.hostname
          );
        } catch (e) {
          console.log("error", e);
          return false; // Invalid URL
        }
      };

    const handleSave = () => {
        let temp_sources = sources.length > 0 ? [...sources] : []
        let temp_source = source ? {...source} : {}
        if (source) {
            temp_sources.pop(source)
        }
        temp_source["source"] = newSource
        if (isValidBaseUrl(url)) {
            temp_source["baseUrl"] = url
        }
        else {
            setAlertSeverity("error")
            setAlertMessage("Invalid Base URL")
            setSnackbarOpen(true)
            return
        }
        temp_sources.push(temp_source)
        console.log(temp_sources)
        setSources(temp_sources)
        setAlertSeverity("success")
        setAlertMessage("Base URL saved successfully")
        setSnackbarOpen(true)
    }

    return <div className="grid grid-cols-9 gap-3 my-3">
    <input placeholder="Source name" onChange={(e) => setNewSource(e.target.value)} disabled={!edit} value={newSource} className="bg-[#171821] rounded-md p-2.5 col-span-3" />
    <input placeholder="https://api-example.com" onChange={(e) => setUrl(e.target.value)} disabled={!edit} value={url} className="bg-[#171821] rounded-md p-2.5 col-span-5 overflow-x-scrol" />
    <div onClick={() => setEdit(!edit)} className="bg-[#171821] rounded-md p-2.5 col-span-1 flex justify-center items-center cursor-pointer hover:bg-[#E45799]">
        {edit ? <RiSaveLine onClick={handleSave} size={16} /> : <RiEditBoxLine size={16} />}
    </div>
    <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
  </div>
}

export default BaseUrl;
