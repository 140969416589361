import React, { useState } from 'react'
import './Profile.css'
import { Typography } from '@mui/material'
import EyeOpen from '../../../icons/eye-open.svg'
import EyeClose from '../../../icons/eye-closed.svg'
import IssuesTableDropdown from '../../IssuesTable/IssuesTableDropdown/IssuesTableDropdown'
import KeyDropdown from './KeyDropdown/KeyDropdown'
import { useSelector } from 'react-redux'
import User from '../../../icons/avatar.svg'
import Mail from '../../../icons/mail.svg'
import Organisation from '../../../icons/organisation.svg'
import Designation from '../../../icons/designation.svg'
/*

Profile component that renders the profile page of the application.
The Profile component uses the useSelector hook from react-redux to access the state.
The Profile component displays the user details such as username, email, API key, etc.
The Profile component uses the useState hook to manage the state of the user details.
It contains input fields for username, email, designation, organisation, subscription, and API key.
By toggling the eye icon, the user can view the API key.
The Profile component also contains a button to add a new API key.

*/
const Profile = () => {
    const {user}=useSelector(state=>state.user) // Get the user from the state

    // Initial state of user details
    const [userName, setUserName] = useState(user.name)
    const [email, setEmail] = useState(user.email)
    const [organisation, setOrganisation] = useState(user.orgDetails)
    const [apiKey, setApiKey] = useState(user.api_keys[0])
    const [showKey, setShowKey] = useState(false)


    return (
        <div className='w-full grid grid-cols-2 gap-6 p-6'>
            <div className="">
                <div className="username-div">
                    <Typography variant='h6'>Username</Typography>
                    <div className="bg-[#171821] rounded-md p-2 flex items-center my-2">
                        <img src={User} alt='user' className='mr-2' width={15} height={15} />
                        <input
                            value={userName}
                            placeholder='Username'
                            className='bg-transparent border-none outline-none w-full'
                        />
                    </div>
                </div>
                <div className="email-div">
                    <Typography variant='h6'>Email</Typography>
                    <div className="bg-[#171821] rounded-md p-2 flex items-center my-2">
                        <img src={Mail} alt='mail' className='mr-2' width={15} height={15} />
                        <input
                            value={email}
                            placeholder='Email'
                            className='bg-transparent border-none outline-none w-full'
                        />
                    </div>
                </div>
                <div className="designation-div">
                    <Typography variant='h6'>Designation</Typography>
                    <div className="bg-[#171821] rounded-md p-2 flex items-center my-2">
                        <img src={Designation} alt='designation' className='mr-2' width={15} height={15} />
                        <input
                            value={organisation.designation}
                            placeholder='Designation'
                            className='bg-transparent border-none outline-none w-full'
                        />
                    </div>
                </div>
                <div className="organisation-div">
                    <Typography variant='h6'>Organisation</Typography>
                    <div className="bg-[#171821] rounded-md p-2 flex items-center my-2">
                        <img src={Organisation} alt='organisation' className='mr-2' width={15} height={15} />
                        <input
                            value={organisation.organisation}
                            placeholder='Organisation name'
                            className='bg-transparent border-none outline-none w-full'
                        />
                    </div>
                </div>
                <div className="subscription-div">
                    <Typography variant='h6'>Subscription</Typography>
                    <div className="subscription-input">
                        <input
                            placeholder='Subscription'
                        />
                    </div>
                </div>

            </div>
            <div className="">
                <div className="api-key">
                    <Typography variant='h6'>API Key</Typography>
                    <div className="api-key-input">
                        <input
                            value={
                                showKey ? apiKey :apiKey.split('').map(() => '•').join('')}
                            placeholder='API Key'
                        />
                        <span>
                            <div className="show-key">
                            {showKey ? <img src={EyeOpen} alt='eye' onClick={() => setShowKey(!showKey)} /> :
                                <img src={EyeClose}
                                    alt='eye' onClick={() => setShowKey(!showKey)} />}
                            </div>
                            <div className="key-options">
                            <KeyDropdown/>
                            </div>
                        </span>
                    </div>
                    <div className="api-key-btn">
                        <button>Add New Key</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
