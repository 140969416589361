import React, { useState } from "react";
import "./BugTracker.css";
import CoverageComponent from "../CoverageComponent/CoverageComponent";
import IssuesTable from "../IssuesTable/IssuesTable";
import { Dialog, Typography } from "@mui/material";
import EditIcon from "../../icons/edit-icon.svg";
import DeleteIcon from "../../icons/delete-icon.svg";
import PlusIcon from "../../icons/plus-icon.svg";
import Navbar from "../Navbar/Navbar";

/*

BugTracker component that renders the bug tracker page of the application.
It uses the useState hook to manage the state of the modal.
It contains a table that displays the list of bugs.
It uses the IssuesTable component to display the bugs.
It uses the Dialog component from Material-UI to display the modal.
It contains buttons to view base URLs and add new test cases.

*/
const BugTracker = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full grid grid-cols-10 gap-8">
      <div className="col-span-2">
        <Navbar />
      </div>
      <div className="col-span-6 py-8 flex flex-col justify-start">
        <div>
          <select name="workspace-select"  className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Workspace
            </option>
          </select>
          {/* <div className="mt-btns">
                        <button className='baseUrlsBtn' onClick={handleModalOpen}>View Base URLs</button>
                        <button onClick={addTestCase} className='newTCBtn'>Add New Test Case</button>
                    </div> */}
          <Dialog
            open={modalOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                height: "549px",
                width: "586px",
                backgroundColor: "#21222D",
                color: "#fff",
                boxShadow: "none",
                borderRadius: "12px",
                border: "1px solid #3D3D3D",
              },
            }}
          >
            <div className="baseUrlModalContainer">
              <Typography
                sx={{ fontFamily: "Roboto", fontWeight: "400" }}
                variant="h6"
              >
                Base URLs
              </Typography>
              <div className="baseUrlsModal">
                <div className="url">
                  <p>https://stg-api.shopflo.com</p>
                </div>
                <div className="delEdit">
                  <div className="editUrlBtn">
                    <img className="editIcon" src={EditIcon} alt="edit-icon" />
                  </div>
                  <div className="deleteUrlBtn">
                    <img
                      className="delIcon"
                      src={DeleteIcon}
                      alt="delete-icon"
                    />
                  </div>
                </div>
              </div>
              <div className="plusBtn">
                <img src={PlusIcon} alt="plus-icon" />
              </div>
              <div className="addCancel">
                <button className="addBtn">Add</button>

                <button className="cancelBtn">Cancel</button>
              </div>
            </div>
          </Dialog>
        </div>
        <div className="grid grid-cols-3 w-[90%] my-12 self-end">
          <select name="repo-select"  className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Repository
            </option>
          </select>
          <select name="branch-select"  className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Branch
            </option>
          </select>
          <select name="commit-select"  className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Commit
            </option>
          </select>
        </div>
        <div className="testSuite-middle-bottom">
          <IssuesTable isBugs={true} />
        </div>
      </div>
      <div className="col-span-2">
        <CoverageComponent isBugs={true} />
      </div>
    </div>
  );
};

export default BugTracker;
