import { createReducer } from "@reduxjs/toolkit";

// Initial state for the assertion reducer
const initialState = {
    loading: false,
    assertions: null,
    error: null,
    message: null,
    };

/*

The assertionReducer is created with the help of the createReducer function from the redux toolkit.
The initial state for the assertion reducer is defined.
The assertionReducer handles actions related to fetching individual assertions.
Each action has a corresponding case in the assertionReducer - request, success, and failure.

*/

export const assertionReducer = createReducer(initialState, (builder) => {
    builder
    .addCase("fetchIndividualAssertionsRequest", (state, action) => {
        state.loading = true;
    })
    .addCase("fetchIndividualAssertionsSuccess", (state, action) => {
        state.loading = false;
        state.assertions = action.payload;
    })
    .addCase("fetchIndividualAssertionsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
})