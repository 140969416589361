import React, { useEffect, useMemo, useState } from "react";
import "./IndividualTest.css";
import Sidebar from "../Sidebar/Sidebar";
import { Typography, duration } from "@mui/material";
import ArrowLeft from "../../icons/arrow-left.svg";
import Clock from "../../icons/clock.svg";
import StopWatch from "../../icons/stopwatch.svg";
import Calendar from "../../icons/calendar.svg";
import TypeBtn from "../TypeBtn/TypeBtn";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  fetchAllTC,
  fetchIndividualAPI,
  fetchTC,
  getAllAPIs
} from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import Navbar from "../Navbar/Navbar";

/*

IndividualTest component that renders the individual test case page.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useEffect hook to fetch the test cases when the component mounts.
It renders the Sidebar component.
The Sidebar component displays the list of assertions for the selected API.
This component renders on the /test/:id route.

*/

const IndividualTest = () => {
  const { id } = useParams(); // Get the id from the URL
  const dispatch = useDispatch();
  const { loading, testCases } = useSelector((state) => state.testCases); // Get the test cases from the state
  const { loading: apiLoading, apis } = useSelector((state) => state.apis); // Get the APIs from the state

  useEffect(() => {
    dispatch(fetchAllTC(id)); // Fetch all test cases for the id
  }, [dispatch]);

  let tcName = "";
  let tcNumber = 0;
  let tcDate = "";
  let tcTime = 0;
  let tcDuration = 0;
  let tcPass = null;

  // Find the test case with the given id
  if (testCases) {
    testCases?.tests?.forEach((test) => {
      if (test?.id === id) {
        tcName = test?.title;
        tcNumber = test?.tcNumber;
        tcDate = test?.date;
        tcTime = test?.time;
        tcDuration = test?.duration;
        tcPass = test?.pass;
      }
    });
  }
  console.log(tcPass)
  const [selectedRow, setSelectedRow] = useState(0);
  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  let data = []; // Create an array to store the apis
  let allAssertions = []; // Create an array to store the assertions

  if (apis != undefined && apis.data != undefined) {
    apis?.data?.forEach((api) => {
      data.push({
        apiId: api?.api?._id,
        apiTitle: api?.api?.title,
        assertions: api?.api?.assertions,
        pass: api?.api?.pass,
        // failed: api?.api?.fails.length,
        step: api?.api?.step,
        method: api?.api?.method,
        path: api?.api?.path,
      });
    });
    // data = data;
  }
  // var res = data.sort((a, b) => b.step-a.step)
  // console.log(res.reverse());
  let assertions = []; // Array to store filtered assertions

  const getPassStatus = (type, assertions) => {
    let passCount = 0;
    let failCount = 2;
    if(assertions?.status.pass){
        passCount += 1;
        failCount -= 1;
      }
      if(assertions?.response?.pass){
        passCount += 1;
        failCount -= 1;
      }
    return type == 'pass' ? passCount: failCount;
  }
//   if (allAssertions?.length > 0) {
//     // Filter the assertions based on the selected row
//     assertions = allAssertions.filter(
//       (assertion) => assertion?.apiId === data[selectedRow].apiId
//     );
//   }
  return loading == true || apiLoading == true ? (
    <Loader loading={apiLoading} />
  ) : (
    <div className="w-full grid grid-cols-10 gap-8 overflow-hidden">
      <div className="col-span-2">
        <Navbar />
      </div>
      <div className="col-span-6 text-white">
        <div className="back-arrow">
          <div className="arrow-icon">
            <Link to="/test-suite">
              <img src={ArrowLeft} alt="arrow-left" />
            </Link>
          </div>
          <div className="time-info">
            <div className="calendar-div">
              <img className="time-info-icon" src={Calendar} alt="calendar" />
              <p>{tcDate}</p>
            </div>
            <div className="clock-div">
              <img className="time-info-icon" src={Clock} alt="clock" />
              <p>{tcTime}</p>
            </div>
            <div className="watch-div">
              <img className="time-info-icon" src={StopWatch} alt="stopwatch" />
              <p>{tcDuration / 1000} secs</p>
            </div>
          </div>
        </div>
        <div className="flex justify-start items-center">
          <div className="bg-[#21222d] min-w-[80px] text-center py-2 rounded-sm mr-3">
            <p>{tcNumber}</p>
          </div>
          <Typography variant="h6">{tcName}</Typography>
        </div>
        <div className=" my-8">
          <Typography variant="h6">Priority</Typography>
          <select className="bg-[#21222d] px-6 py-3">
            <option>High</option>
            <option>Medium</option>
            <option>Low</option>
          </select>
        </div>
        <div>
          <Typography variant="h6">API Sequence</Typography>
          <table className="assertions-table">
            <thead>
              <tr className="api-cols">
                <th className="api-header">API</th>
                <th className="assertions-header">Assertions</th>
                <th className="pass-header">Passed</th>
                <th className="fail-header">Failed</th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 &&
                data
                .sort((a, b) => a.step - b.step)
                .map((api, index) => (
                  <tr
                    className={`api-table-row${
                      selectedRow === index ? "-selected" : ""
                    }`}
                    key={api.apiId}
                    onClick={() => handleRowClick(index)}
                  >
                    <td className="min-w-fit pl-4">
                      <Link className="min-w-fit" to={`/api/${api.apiId}`}>{api.apiTitle}</Link>
                    </td>
                    <td className="assertions-col">
                      <TypeBtn type="assertions" tests={2} />
                    </td>
                    <td className="passed-col">
                      <TypeBtn type="pass" tests={getPassStatus('pass', api.assertions)} />
                    </td>
                    <td className="failed-col">
                      <TypeBtn type="fail" tests={getPassStatus('fail', api.assertions)} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-span-2">
        <Sidebar apis={data} status={tcPass} />
      </div>
    </div>
  );
};

export default IndividualTest;