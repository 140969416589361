const OnboardingNav = ({active}) => {
    return <div className="flex justify-between py-8 px-16 w-full items-center">
    <div className="">Devzery</div>
    <div className="flex justify-end items-center text-sm">
        <div className="flex items-center mr-6"><span className={"mr-2 rounded-full w-[24px] h-[24px] block flex items-center justify-center " + (active > 1 ? "bg-[#31744A]" : active == 1 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>1</span>Integrate SDK</div>
        <div className="flex items-center mr-6"><span className={"mr-2 rounded-full w-[24px] h-[24px] block flex items-center justify-center " + (active > 2 ? "bg-[#31744A]" : active == 2 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>2</span>Collect APIs</div>
        <div className="flex items-center mr-6"><span className={"mr-2 rounded-full w-[24px] h-[24px] block flex items-center justify-center " + (active > 3 ? "bg-[#31744A]" : active == 3 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>3</span>Verify APIs</div>
        <div className="flex items-center"><span className={"mr-2 rounded-full w-[24px] h-[24px] block flex items-center justify-center " + (active > 4 ? "bg-[#31744A]" : active == 4 ? "bg-[#F257A0]" : "bg-[#787B95] text-[#E7E7E7]")}>4</span>Swagger Document</div>
    </div>
</div>
}

export default OnboardingNav