import { useState } from "react";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
const HeaderRow = ({headerKey, headers, setHeaders, val}) => {
    const [newKey, setNewKey] = useState(headerKey)
    const [keyDisabled, setKeyDisabled] = useState(true);
    const [value, setValue] = useState(val)
    const [valueDisabled, setValueDisabled] = useState(true)
    
    const handleKeySave = () => {
        setKeyDisabled(!keyDisabled)
        let temp = {...headers}
        temp[newKey] = temp[headerKey];
        setHeaders(temp)
        // setNewKey(headerKey)
        // delete temp[headerKey];
      }

      const handleValueSave = () => {
        setValueDisabled(!valueDisabled)
        let temp = {...headers}
        temp[headerKey] = value
        setHeaders(temp)
      }

    return <div className="mb-3">
    <div className='px-4 py-3 flex justify-between rounded-t-lg border border-1 border-[#46485D] relative'>
    <div className="w-full text-wrap overflow-x-scroll mr-2">{headerKey}</div>
      {/* {keyDisabled ? <div className="w-full text-wrap overflow-scroll mr-2">{headerKey}</div> : <input type="text" disabled={keyDisabled} value={newKey} className="border-transparent bg-inherit mr-2 active:border-transparent w-full h-full z-10" onChange={(e) => setNewKey(e.target.value)} />} */}
      {/* {keyDisabled ? <RiEditBoxLine className="cursor-pointer z-20" onClick={() => setKeyDisabled(!keyDisabled)} /> : <RiSaveLine className="cursor-pointer z-20" onClick={handleKeySave} />} */}
    </div>
    <div className="bg-[#08040A] p-4 flex justify-between rounded-b-lg border border-1 border-t-0 border-[#46485D]">
      {valueDisabled ? <div className="w-full text-wrap overflow-scroll mr-2">{value}</div> : <input type="text" disabled={valueDisabled} value={value} className="border-transparent bg-inherit mr-2 active:border-transparent w-full h-full z-10" onChange={(e) => setValue(e.target.value)} />}
      {valueDisabled ? <RiEditBoxLine className="cursor-pointer z-20" onClick={() => setValueDisabled(!valueDisabled)} /> : <RiSaveLine className="cursor-pointer z-20" onClick={handleValueSave} />}
    </div>
  </div>
}

export default HeaderRow;
