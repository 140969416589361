import { useSelector } from "react-redux";
import CoverageComponent from "../CoverageComponent/CoverageComponent";

const RightSidebar = () => {
    const {
        loading: testsCasesLoading,
        testCases,
      } = useSelector((state) => state.testCases); // Get the test cases from the state
      const {
        loading: apiListLoading,
        methodCount
      } = useSelector((state) => state.apis); // Get the method count from the state

    let assertions = {}
    if (testCases && testCases?.tests?.length > 0) {
        assertions = {
          totalPass: testCases.totalPass,
          totalFail: testCases.totalFail,
        };
      }

    return <CoverageComponent
    isBugs={false}
    test={assertions}
    http={methodCount}
  />
}

export default RightSidebar;