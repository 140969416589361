import React, { useState } from "react";
import "./Sidebar.css";
import { Typography } from "@mui/material";
import CaretForward from "../../icons/caret-forward.svg";
import StopOutline from "../../icons/stop-outline.svg";
import PauseIcon from "../../icons/pause-icon.svg";
import IgnoreIcon from "../../icons/ignore.svg";
import Assertion from "../Assertion/Assertion";
import { Dialog } from "@mui/material";
import EditIcon from "../../icons/edit-icon.svg";
import DeleteIcon from "../../icons/delete-icon.svg";
import PlusIcon from "../../icons/plus-icon.svg";

const Sidebar = ({ apis, status }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      {" "}
      <div className="sidebar-top p-3">
        <div className="flex justify-between w-1/2">
          <img
            className="sidebar-icon"
            src={CaretForward}
            alt="caret-forward"
          />
          <img className="sidebar-icon" src={StopOutline} alt="stop-outline" />
          <img className="sidebar-icon" src={PauseIcon} alt="pause-icon" />
          <img className="sidebar-icon" src={IgnoreIcon} alt="ignore-icon" />
        </div>
      </div>
      <div className="text-white bg-[#21222d] h-[100vh] p-3">
        <Dialog
          className="base-url-dialog"
          open={modalOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              height: "549px",
              width: "586px",
              backgroundColor: "#21222D",
              color: "#fff",
              boxShadow: "none",
              borderRadius: "12px",
              border: "1px solid #3D3D3D",
            },
          }}
        >
          <div className="baseUrlModalContainer">
            <Typography
              sx={{ fontFamily: "Roboto", fontWeight: "400" }}
              variant="h6"
            >
              Base URLs
            </Typography>
            <div className="baseUrlsModal">
              <div className="url">
                <p>https://stg-api.shopflo.com</p>
              </div>
              <div className="delEdit">
                <div className="editUrlBtn">
                  <img className="editIcon" src={EditIcon} alt="edit-icon" />
                </div>
                <div className="deleteUrlBtn">
                  <img className="delIcon" src={DeleteIcon} alt="delete-icon" />
                </div>
              </div>
            </div>
            <div className="plusBtn">
              <img src={PlusIcon} alt="plus-icon" />
            </div>
            <div className="addCancel">
              <button className="addBtn">Add</button>

              <button
                className="cancelBtn"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
        <div className="">
          <button className="base-urls-btn" onClick={handleModalOpen}>
            View Base URLs
          </button>
        </div>
        <div className="mt-4">
          <div className="status-header">
            <Typography>Status</Typography>
          </div>
          <div className="tc-status">
            <p>{status ? "Passed" : "Failed"}</p>
          </div>
          <div className="assertion-header">
            <Typography>Assertions</Typography>
          </div>
          {/* <div className="tc-assertions">
            {apis?.map((api) => (
              <Assertion
                // id={test.id}
                parentId={api.apiId}
                title={api.apiTitle}
                assertion={api.assertions}
                pass={api.pass}
                isBugs={false}
              />
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
