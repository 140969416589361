import React, { useEffect, useState } from "react";
import "./AddTC.css";
import AddTCSidebar from "./AddTCSidebar/AddTCSidebar";
import { Typography, Snackbar, Alert } from "@mui/material";
import ArrowLeft from "../../icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import ReqResBody from "../ReqResBody/ReqResBody";
import { addTestCase, fetchReqResByAPI, getAllAPIs } from "../../Actions/tcActions";
import JSONPretty from "react-json-pretty";
import APISequence from "./APISequence/APISequence";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import Loader from "../Loader/Loader";
import Button from '@mui/material/Button';
import { fetchTC } from "../../Actions/tcActions";

/*

AddTC component that renders the Add Test Case page of the application.
It uses the useNavigate hook from react-router-dom to navigate to different routes.
It contains a form to add a new test case.
The form includes fields for Test Case Title, Priority, and API Sequence.
It also includes a button to add a new API sequence.

*/

const AddTC = () => {
  const [allApis, setAllApis] = useState([]);
  const [currentApi, setCurrentApi] = useState({});
  const [addedApis, setAddedApis] = useState({});
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [baseUrl, setBaseUrl] = useState(null);
  const [reqBody, setReqBody] = useState({});
  const [reqBodyDisabled, setReqBodyDisabled] = useState(true);
  const [resContent, setResContent] = useState({});
  const [resContentDisabled, setResContentDisabled] = useState(true);
  const [headers, setHeaders] = useState({});

  const navigate = useNavigate();

  const { loading: useLoading, user } = useSelector((state) => state.user); // Get the user from the state
  const {allApis: apis, loading, message, error} = useSelector((state) => state.apis); // Get the list of apis from the state
  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    // dispatch(fetchReqResByAPI(user?._id))
    dispatch(getAllAPIs());
  }, []);

  useEffect(() => {
    if (apis) {
      const tempApis = [];
      apis?.apiNames?.forEach((api) => {
        tempApis.push(api);
      });
      setAllApis(tempApis);
    }
  }, [apis]);

  const handleAddTC = () => {
    console.log('hi pushed', addedApis)
    const formattedApis = [];
    for (let [ind, api] of Object.entries(addedApis)) {
      formattedApis.push({
        name: api.name,
        baseUrl,
        endpoint: api.source.request.path,
        method: api.source.request.method,
        body: api.source.request.body ?? null,
        headers: headers ? headers : api.source.request.headers,
        expectedStatus: 200,
        response: api?.source?.response
      })
    }
    console.log(formattedApis)
    try {
      dispatch(addTestCase({
        name: title,
        description: description,
        apis: formattedApis
      }))
    } catch (error) {
      // On error
      setAlertSeverity("error");
      setAlertMessage("Failed to Add Test Case");
      setSnackbarOpen(true);
    } finally {
      setAlertSeverity("success");
      setAlertMessage("Successfully Added Test Case");
      setSnackbarOpen(true);
    }
  }

  useEffect(() => {
    if (message) {
      dispatch(fetchTC())
      navigate("/test-suite")
    }
    if (error) {
      console.log(error)
    }
  }, [message, error])

  useEffect(() => {
    if (reqBodyDisabled && addedApis[0]){
      for (let [ind, value] of Object.entries(addedApis)) {
        if (value.id == currentApi.id) {
          addedApis[ind] = {...value, source: {...value.source, request: {...value.source.request, body: typeof(reqBody) === 'string' && reqBody !== 'NA' ? JSON.parse(reqBody) : {}}}}
        }
      }
    }
  }, [reqBodyDisabled])
  useEffect(() => {
    if (resContentDisabled && addedApis[0]){
      for (let [ind, value] of Object.entries(addedApis)) {
        if (value.id == currentApi.id) {
          addedApis[ind] = {...value, source: {...value.source, response: {...value.source.response, content: typeof(resContent) === 'string' && resContent !== 'NA' ? JSON.parse(resContent) : {}}}}
        }
      }
    }
  }, [resContentDisabled])

  return (loading ? <Loader loading={loading} /> :
    <div className="w-full grid grid-cols-10 gap-8 overflow-hidden text-white">
      <div className="col-span-2">
        <Navbar />
      </div>
      <div className="col-span-6">
        <div className="w-full flex justify-between items-center">
          <div
            className="back-arrow"
            onClick={() => {
              navigate("/test-suite");
            }}
          >
            <img src={ArrowLeft} alt="back-arrow" />
          </div>
          <Button
            variant="contained"
            sx={{
              "&.Mui-disabled": {
                background: "#21222d",
                color: "white",
                border: "1px solid #222222",
              },
              backgroundColor: '#D9509B',
              '&:hover': {
                backgroundColor: '#C0408C',
              },
              minWidth: 'fit-content',
              px: 2,
              py: 1,
              textTransform: "unset",
              fontWeight: "400",
            }}
            disabled={!title || !description || !baseUrl || !addedApis[0]}
            onClick={handleAddTC}
          >
            Add Test Case
          </Button>
        </div>
        <div className="w-full grid grid-cols-7 gap-6 mt-4">
          <div className="col-span-4 flex flex-col gap-4">
            <div className="tc-title">
              <Typography variant="h6">Test Case Title</Typography>
              <div className="tc-desc">
                <input onChange={(e) => setTitle(e.target.value)} placeholder="Enter Test Case Title"></input>
              </div>
            </div>
            <div className="tc-title">
              <Typography variant="h6">Test Case Description</Typography>
              <div className="tc-desc">
                <textarea onChange={(e) => setDescription(e.target.value)} placeholder="Enter Test Case Description"></textarea>
              </div>
            </div>
            <div className="tc-title">
              <Typography variant="h6">Test Case Base URL</Typography>
              <div className="tc-desc">
                <input onChange={(e) => setBaseUrl(e.target.value)} placeholder="Enter Test Case Base URL"></input>
              </div>
            </div>
            <div className="api-seq">
              <Typography variant="h6">Add API Sequence</Typography>
              <APISequence setHeaders={setHeaders} setResContent={setResContent} setReqBody={setReqBody} allApis={allApis} setCurrentApi={setCurrentApi} addedApis={addedApis} setAddedApis={setAddedApis} />
            </div>
          </div>
          <div className="flex flex-col gap-4 col-span-3">
            <div className="tc-priority">
              <Typography variant="h6">Priority</Typography>
              <div className="priority-select">
                <select>
                  <option style={{ color: "#F87171" }}>High</option>
                  <option style={{ color: "#FCD34D" }}>Medium</option>
                  <option style={{ color: "#44A5FF" }}>Low</option>
                </select>
              </div>
            </div>
            <div className="rounded-md w-full">
              <div className="bg-[#21222D] flex justify-between items-center p-3">Request {reqBodyDisabled ? <RiEditBoxLine className="cursor-pointer" onClick={() => setReqBodyDisabled(!reqBodyDisabled)} /> : <RiSaveLine className="cursor-pointer" onClick={() => setReqBodyDisabled(!reqBodyDisabled)} />}</div>
              <div className="bg-[#08040A] min-h-[25vh] max-h-[30vh] p-3 overflow-scroll relative">
                {reqBodyDisabled ? <JSONPretty
                  style={{ overflowY: "scroll", height: "90%" }}
                  id="json-pretty"
                  themeClassName="text-sm"
                  keyStyle="color: #E45799;"
                  data={reqBody ?? 'NA'}
                ></JSONPretty> : <textarea value={reqBody} className="p-3 w-full h-full bg-white/30 absolute top-0 left-0 z-20" disabled={reqBodyDisabled} type="text" onChange={(e) => setReqBody(e.target.value)}/>}
              </div>
            </div>
            <div className="rounded-md w-full">
              <div className="bg-[#21222D] p-3 flex justify-between items-center">Response {resContentDisabled ? <RiEditBoxLine className="cursor-pointer" onClick={() => setResContentDisabled(!resContentDisabled)} /> : <RiSaveLine className="cursor-pointer" onClick={() => setResContentDisabled(!resContentDisabled)} />}</div>
              <div className="bg-[#08040A] min-h-[25vh] max-h-[30vh] p-3 overflow-scroll relative">
                {resContentDisabled ? <JSONPretty
                  style={{ overflowY: "scroll", height: "90%" }}
                  id="json-pretty"
                  themeClassName="text-sm"
                  keyStyle="color: #E45799;"
                  data={resContent ?? 'NA'}
                ></JSONPretty>: <textarea value={resContent} className="p-3 w-full h-full bg-white/30 absolute top-0 left-0 z-20" disabled={resContentDisabled} type="text" onChange={(e) => setResContent(e.target.value)}/>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <AddTCSidebar headers={headers ?? {}} setHeaders={setHeaders} />
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        >
        <Alert severity="success">Copied to clipboard</Alert>
    </Snackbar>
    </div>
  );
};

export default AddTC;
