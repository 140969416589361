import SDKCard from './SDKCard/SDKCard';
import { Typography, Alert, Snackbar } from '@mui/material';
import { Link } from "react-router-dom";
import { PiLink, PiArrowLeftBold } from "react-icons/pi";
import nodeIcon from '../../../icons/node-icon.svg'
import pythonIcon from '../../../icons/python-icon.svg'
import javaIcon from '../../../icons/java-icon.svg'
import golangIcon from '../../../icons/golang-icon.svg'
import CopyIcon from '../../../icons/copy-icon.svg';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getAPIKey, getAPICount } from '../../../Actions/tcActions';
import {CircularProgress} from '@mui/material';

const SDK = ({setPageStatus}) =>{
    const [copied, setCopied] = useState(false);
    const [noKey, setNoKey] = useState(false)

    const dispatch = useDispatch();

    const {
        loading: userLoading,
        error,
        user,
        isNew,
        isAuthenticated,
    } = useSelector((state) => state.user);
    const {
        loading: apiLoading,
        apiKey,
        userApiCount
    } = useSelector((state) => state.apis);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(apiKey?.key);
        setCopied(true);
        setTimeout(() => {
        setCopied(false);
        }, 1000);
    };

    const handleProceed = () => {
        setPageStatus({keyIntegrated: true, apiCollected: false, verificationCompleted: false});
    }

    useEffect(() => {
        dispatch(getAPIKey(user?.api_keys[0]));
        dispatch(getAPICount())
    }, [])

    const renderComponent = () => {
        setTimeout(() => {
            setNoKey(true)
        }, 4000);
        return noKey ? <div onClick={() => {dispatch(getAPICount()); setNoKey(false); setTimeout(() => {
            setNoKey(true)
        }, 4000);}} className="cursor-pointer w-1/4 py-3 mt-16 rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] text-center">Refresh</div> : <div className="mt-16 flex items-center"><span className='text-[#D9509B] flex items-center mr-3'><CircularProgress size={20} color="inherit" /></span>Waiting for the API key to be added into your project</div>
    }

    return  <div className="px-16 py-10 flex items-start justify-between">
    <Link to="/org-details"><PiArrowLeftBold /></Link>
    <div className="w-[90%] flex flex-col items-center justify-between">
        <div className="text-[#F37BB3] text-xl font-semibold">Integrate Our SDK into your application</div>
        <div className="mt-24">Get started by using this API key with all our SDKs</div>
        <div className="bg-[#0F1011] rounded-md border border-1 border-[#585858] px-4 py-2.5 mt-3 w-1/2 flex justify-between items-center text-[#B0B0B0]">{apiKey?.key}<img
                onClick={copyToClipboard}
                className='copy-icon'
                src={CopyIcon} alt="copy icon" /></div>
        <div className="mt-20">Select required SDK</div>
        <div className="grid grid-cols-2 gap-3 mt-4">
            <SDKCard lang="Node" image={nodeIcon} docsUrl="https://github.com/devzery/devzery_middleware_express" githubUrl="https://github.com/devzery/devzery_middleware_express"/>
            <SDKCard lang="python" image={pythonIcon} docsUrl="https://github.com/devzery/devzery_middleware" githubUrl="https://github.com/devzery/devzery_middleware"/>
            <SDKCard lang="java" image={javaIcon} docsUrl="https://github.com/devzery/devzery-middleware-springboot" githubUrl="https://github.com/devzery/devzery-middleware-springboot"/>
            {/* <SDKCard lang="golang" image={golangIcon} docsUrl="https://github.com/devzery/devzery_middleware" githubUrl="https://github.com/devzery/devzery_middleware"/> */}
        </div>
        {userApiCount?.data?.count > 0 ? 
        <div onClick={handleProceed} className="cursor-pointer w-1/3 py-3 mt-16 rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] text-center">Proceed to API analysis</div> 
        : renderComponent()}
    </div>
    <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1">Need Help?</div>
    <Snackbar
        open={copied}
        autoHideDuration={1000}
        onClose={() => setCopied(false)}
        >
        <Alert severity="success">Copied to clipboard</Alert>
    </Snackbar>
</div>
}

export default SDK