import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useDispatch } from 'react-redux'
import '../IssuesTableDropdown/IssuesTableDropdown.css'
import { Dialog } from '@mui/material'

/*

SortBtnMenu component that renders the sort button for tables.
It displays the sort options for the table.
The component uses the ITEM_HEIGHT constant to set the height of the menu items.

*/
const ITEM_HEIGHT = 48;
const SortBtnMenu = ({ id, prodId,editDetails, bug }) => {
    const dispatch=useDispatch();
    const [isDeleted, setIsDeleted] = useState(false)
    const [isName, setIsName] = useState(false)
    const [name, setName] = useState('')
    const [openDialog, setOpenDialog] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    return (
        <div className='issuesDropdownContainer'>

            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ color: "white", marginTop: "-0.7vw" }}
            >
                <IoEllipsisHorizontalSharp />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        position:"absolute",
                        width: '15ch',
                        color: "white",
                        opacity: "1",
                        backgroundColor: "#21222d",
                        borderRadius:"4px"
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem onClick={
                    () => {setOpenDialog(true)}}>
                    Priority
                </MenuItem>
               </Menu>
            <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{
                style: {
                    minHeight: "45vh",
                    minWidth: '40vw',
                    backgroundColor: '#191a23',
                    color: '#fff',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    border: '2px solid #fff'
                }
            }}>
              
            </Dialog>
        </div>
    )
}

export default SortBtnMenu
