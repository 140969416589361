import "../SignIn/SignIn.css";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import signin from "../../icons/signin.png";
import { sendOrgDetails } from "../../Actions/userActions";
import { fetchTC } from "../../Actions/tcActions";

const OrgDetails = () => {
  const {
    loading: userLoading,
    user,
    isNew
  } = useSelector((state) => state.user);

  // Update the form states
  const [designation, setDesignation] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [workspace, setWorkspace] = useState("");
  const [productUrl, setProductUrl] = useState("");

  // Change the state of the snackbarOpen, alertSeverity and alertMessage
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(sendOrgDetails({ designation, organisation, workspace, productUrl }));
  };

  let storedUser = user ? user : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  useEffect(() => {
    if (storedUser && storedUser.orgDetails && isNew) {
      navigate("/integrate-sdk");
    } else if (storedUser && isNew == false) {
      dispatch(fetchTC());
      navigate("/test-suite");
    }
  }, [user, isNew]);

  return (
    <div className="grid grid-cols-10 w-full overflow-hidden bg-[#080808]">
      <div className="col-span-3 w-full h-[100vh] relative">
        <img className="w-full h-full" src={signin} alt="" />
        <div className="loginGradient " />
        {/* <div className="w-full absolute h-full top-0 left-0 bg-[#14141E]/30 " /> */}
      </div>
      <div className="col-span-4 text-white p-24 pr-12">
        <div className="font-bold text-xl text-[#F37BB3]">Welcome {user?.name}</div>
        <div className="text-[#E7E7E7] mt-3">We would love to know more about you</div>
        <div className="mt-8">
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="mt-4">
                <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                  Designation <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                  value={designation}
                  required
                  placeholder="Enter your designation"
                  id="outlined-required"
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                />
              </div>
              <div className=" mt-4">
                <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                  Organisation <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  required
                  className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                  value={organisation}
                  id="outlined-required"
                  placeholder="Enter your organisation name"
                  type="Organisation"
                  onChange={(e) => {
                    setOrganisation(e.target.value);
                  }}
                />
              </div>
              <div className="mt-4">
                <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                  Workspace <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                  value={workspace}
                  required
                  placeholder="Enter your workspace name"
                  id="outlined-required"
                  onChange={(e) => {
                    setWorkspace(e.target.value);
                  }}
                />
              </div>
              <div className="mt-4">
                <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                  Product URL <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                  value={productUrl}
                  required
                  placeholder="Enter your product URL"
                  id="outlined-required"
                  onChange={(e) => {
                    setProductUrl(e.target.value);
                  }}
                />
              </div>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                className="loginButton"
                sx={{
                  width: "100%",
                  padding: "12px 16px",
                  height: "44px",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  marginTop: "40px",
                  borderRadius: "4px",
                  backgroundColor: "#E45799",
                  ":hover": {
                    backgroundColor: "#E45799",
                  },
                  textTransform: "unset",
                }}
              >
                Proceed
              </Button>
            </form>
          </div>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default OrgDetails;