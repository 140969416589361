import React from 'react'
import './Notify.css'
import { Typography } from '@mui/material'

const Notify = () => {
    return (
        <div className='notify-container'>
            <div className="support-email">
                <div className="email-heading">
                    <Typography variant='h6'>
                        Send updates to email
                    </Typography>
                </div>
                <div className="email-text">
                    <input type="text" placeholder='Enter your email' />
                </div>
            </div>
            <div className="get-updates">
               <div className="get-updates-heading">
               <Typography>
                    Get Updates on
                </Typography>
               </div>
                <div className="updates-checkboxes">
                    <div className="fail-update">
                    <input type="checkbox" id="updates" name="updates" value="updates" />
                    <label for="updates">Test failed</label>
                    </div>
                    <div className="complete-update">
                    <input type="checkbox" id="updates" name="updates" value="updates" />
                    <label for="updates">Test run completed</label>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Notify
