import React from 'react'
import './TestStatus.css'
import { Typography } from '@mui/material'

/*

The TestStatus component renders the status of the test case.
It displays the status of the test case and the value of the status.
The component uses the status prop to determine the color of the text.


*/
const TestStatus = ({ status, val, isBugs }) => {

  let textColor = '';

  // Set the text color based on the status prop
  switch (status) {
    case 'Passed':
      textColor = '#4ADE80';
      break;
    case 'Failed':
      textColor = '#F87171';
      break;
    case 'Open':
      textColor = "#F37BB3"
      break;
    case 'Closed':
      textColor = "#96A7FF"
      break;
  }
  let name = isBugs ? 'bugs' : 'test';
  return (
    <div className={name + 'StatusContainer'}>
      <Typography variant='h5' style={{ color: textColor }}>
        {status}
      </Typography>
      <p>
        {val}
      </p>
    </div>
  )
}

export default TestStatus
