import { useState } from "react";

const APICard = ({ item, index }) => {
  const [selected, setSelected] = useState(false)
  const getMethodClasses = (method) => {
    switch (method.toLowerCase()) {
      case "post":
        return "bg-[#816F33]/40 text-[#FCD34D]";
      case "get":
        return "bg-[#31744A]/40 text-[#4ADE80]";
      case "put":
        return "bg-[#2F5A83]/40 text-[#44A5FF]";
      case "delete":
        return "bg-[#804343]/40 text-[#F87171]"
      case "patch":
        return "bg-[#6B4FBB4D] text-[#96A7FF]"
    }
  }

  return <div className={"cursor-pointer bg-[#080808] w-full mb-3 border border-1 border-l-4 rounded-lg p-4 flex justify-start items-center " + (item.id == index ? "border-[#F37BB3]" : "border-[#3E4045]")}>
    <div className={"rounded-md min-w-[80px] text-center py-2 mr-3 text-sm " + getMethodClasses(item.source.request.method)}>
      {item.source.request.method}
    </div>
    <div className="min-w-[60%]">
      <div className="mb-2 font-semibold">{item.name}</div>
      <div className="text-[10px] text-[#B0B0B0] w-full overflow-hidden text-ellipsis whitespace-normal clamp-2 hover:overflow-visible hover:whitespace-normal hover:absolute hover:bg-[#080808] hover:max-w-xs hover:shadow-lg hover:z-10">
        {String(item.source.request.path)}
      </div>
    </div>
  </div>
}

export default APICard;