import { createReducer } from '@reduxjs/toolkit';

// Initial state for the API reducer
const initialState = {
    loading: false,
    apis: null,
    allApis:null,
    individualAPI: null,
    apiKey:null,
    apiList:null,
    userApiCount: null,
    error: null,
    message: null,
    methodCount: null,
    userApis: null,
    apiNames: null,
    responseBody: null,
    swaggerDoc: null,
    sources: null,
    baseUrls: null
};

/*

The apiReducer is created with the help of the createReducer function from the redux toolkit.
The initial state for the API reducer is defined.
The apiReducer handles actions related to fetching individual APIs.
Each action has a corresponding case in the apiReducer - request, success, and failure.
The apiReducer handles actions related to fetching all test cases.
The apiReducer handles actions related to fetching request and response data by API.
The apiReducer handles actions related to fetching all APIs.
The apiReducer handles actions related to fetching request names.

*/

export const apiReducer = createReducer(initialState, (builder) => {
    builder
    .addCase("fetchIndividualAPIRequest", (state, action) => {
        state.loading = true;
    })
    .addCase("fetchIndividualAPISuccess", (state, action) => {
        state.loading = false;
        state.individualAPI = action.payload;
    })
    .addCase("fetchIndividualAPIFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase("fetchAllTCRequest", (state, action) => {
        state.loading = true;
    })
    .addCase("fetchAllTCSuccess", (state, action) => {
        state.loading = false;
        state.apis = action.payload;
    })
    .addCase("fetchAllTCFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase("fetchReqResByAPIRequest", (state, action) => {
        state.loading = true;
    })
    .addCase("fetchReqResByAPISuccess", (state, action) => {
        state.loading = false;
        state.apiList = action.payload;
        state.methodCount = action.payload.count
    })
    .addCase("fetchReqResByAPIFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase("getAllAPIsRequest", (state, action) => {
        state.loading = true;
    })
    .addCase("getAllAPIsSuccess", (state, action) => {
        state.loading = false;
        state.allApis = action.payload;
    })
    .addCase("getAllAPIsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase('addNamedApiRequest', (state, action) => {
        state.loading = true;
    })
    .addCase('addNamedApiSuccess', (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
    })
    .addCase('addNamedApiFailure', (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase("getAPIKeyRequest", (state, action) => {
        state.loading = true;
    })
    .addCase("getAPIKeySuccess", (state, action) => {
        state.loading = false;
        state.apiKey = action.payload;
    })
    .addCase("getAPIKeyFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    })
    .addCase('getAPICountRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('getAPICountSuccess',(state,action)=>{
        state.loading = false
        state.userApiCount = action.payload
    })
    .addCase('getAPICountFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('getUserApisRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('getUserApisSuccess',(state,action)=>{
        state.loading = false
        state.userApis = action.payload
    })
    .addCase('getUserApisFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('getResponseBodyRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('getResponseBodySuccess',(state,action)=>{
        state.loading = false
        state.responseBody = action.payload
        state.error = null
    })
    .addCase('getResponseBodyFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('clearError',(state,action)=>{
        state.error = null
    })
    .addCase('getSwaggerDocRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('getSwaggerDocSuccess',(state,action)=>{
        state.loading = false
        state.swaggerDoc = action.payload
        state.error = null
    })
    .addCase('getSwaggerDocFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('getSourcesRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('getSourcesSuccess',(state,action)=>{
        state.loading = false
        state.sources = action.payload
    })
    .addCase('getSourcesFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
    .addCase('getBaseURLsRequest',(state,action)=>{
        state.loading = true
    })
    .addCase('getBaseURLsSuccess',(state,action)=>{
        state.loading = false
        state.baseUrls = action.payload
    })
    .addCase('getBaseURLsFailure',(state,action)=>{
        state.loading = false
        state.error = action.payload
    })
})