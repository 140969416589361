import React from 'react'
import './Method.css'
import { Typography } from '@mui/material'

const Method = ({method,val}) => {
    let textColor = '';

  // Set color based on method
  switch (method) {
    case 'GET':
      textColor = '#4ADE80';
      break;
    case 'POST':
      textColor = '#FCD34D';
      break;
    case 'PUT':
      textColor = '#44A5FF';
      break;
    case 'PATCH':
      textColor = '#BA76FF';
      break;
    case 'DELETE':
      textColor = '#F87171';
      break;
  }
    return (
        <div className={`methodContainer`}>
            <Typography variant='h5' style={{color:textColor}}>
                {method}
            </Typography>
            <p>
            {val}
            </p>
        </div>
    )
}

export default Method
