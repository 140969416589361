import React from "react";
import "./AddTCSidebar.css";
import { HiPlusSmall } from "react-icons/hi2";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";
import { useState } from "react";
import HeaderRow from "./HeaderRow/HeaderRow";
import { Dialog, Typography } from "@mui/material";

/*

AddTCSidebar component that renders the sidebar for adding a new test case on the right side of the app.
It contains the API Details section with Request and Expected Response fields.
It also contains the Headers section with Type and Value input fields.
It includes buttons for Add and Cancel actions.

*/
const AddTCSidebar = ({headers, setHeaders}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [newHeader, setNewHeader] = useState({key: "", value: ""})

  const data = {
    couponType: "stackable",
    collectionCondition: true,
    entitleCondition: true,
    discountAmount: 100,
    customerType: "All",
    cartCondition: false,
  };

  const renderHeaders = () => {
    let headerArr = [];
    Object.keys(headers).map(key => headerArr.push(<HeaderRow headerKey={key} val={headers[key]} headers={headers} setHeaders={setHeaders} />))
    return headerArr;
  }

  const handleAddHeader = () => {
    setHeaders({...headers, [newHeader.key]: newHeader.value})
    setNewHeader({key: "", value: ""})
    setModalOpen(false)
  }

  return (
    <div className="w-full bg-[#21222D] h-[100vh] text-white px-2 py-4 overflow-hidden">
      <div className="bg-[#171821] text-[#F37BB3] w-full py-3 flex items-center justify-center mb-4 cursor-pointer" onClick={() => setModalOpen(true)}> <span><HiPlusSmall /></span>Add Headers</div>
      <Typography variant="h6">Headers</Typography>
      <div className="mt-3 h-[85%] overflow-y-scroll">
        {renderHeaders()}
      </div>
      <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            PaperProps={{
              style: {
                width: "586px",
                backgroundColor: "#21222D",
                color: "#fff",
                boxShadow: "none",
                borderRadius: "12px",
                border: "1px solid #3D3D3D",
              },
            }}
          >
            <div className="p-4">
              <div>Headers</div>
              <div className="grid grid-cols-6 gap-4 mt-4">
                <input onChange={(e) => setNewHeader({...newHeader, key: e.target.value})} type="text" placeholder="Key" className="bg-[#171821] border border-1 border-[#3D3D3D] rounded-md p-3 col-span-2" />
                <input onChange={(e) => setNewHeader({...newHeader, value: e.target.value})} type="text" placeholder="Value" className="bg-[#171821] border border-1 border-[#3D3D3D] rounded-md p-3 col-span-4" />
              </div>
              <div className="flex justify-end mt-4">
                <button onClick={handleAddHeader} className="bg-[#F37BB3] text-white px-4 py-2 rounded-md">Add</button>
              </div>
            </div>
          </Dialog>
    </div>
  );
};

export default AddTCSidebar;
