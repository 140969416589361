import React, { useEffect, useState } from 'react'
import './Navbar.css'
import { Typography } from '@mui/material'
import DevzeryIcon from "../../icons/devzery-icon.png";
import TestSuiteIcon from "../../icons/test-suite.svg";
import PluginIcon from "../../icons/plugin-icon.svg";
import BugIcon from "../../icons/bug-icon.svg";
import SettingsIcon from "../../icons/settings-icon.svg";
import OverviewIcon from "../../icons/overview.svg";
import LogoutIcon from "../../icons/logout.svg";
import { useSelector } from 'react-redux';
import NavBtn from './NavBtn/NavBtn';

const buttons = [
    {
        name: 'Test Suite',
        icon: TestSuiteIcon,
        url: '/test-suite'
    },
    {
        name: 'API Overview',
        icon: OverviewIcon,
        url: '/api-overview'
    },
    {
        name: 'Bugs',
        icon: BugIcon,
        url: '/bug-tracker'
    },
    {
        name: 'Integrations',
        icon: PluginIcon,
        url: '/integrations'
    },
    {
        name: 'Settings',
        icon: SettingsIcon,
        url: '/settings'
    }
]
/*

Navbar component that renders the navigation bar (on the left side) of the application.
It contains buttons for Test Suite, API Overview, Bugs, Integrations, and Settings.
It uses the useSelector hook from react-redux to access the state.
It uses the useState hook to manage the selected row state.
It sets the selected row based on the path in the URL.
It handles row click events to update the selected row.

*/
const Navbar = () => {

    const { loading: userLoading, user } = useSelector(state => state.user)

    //select the row based on the path in the url
    const path = window.location.pathname;

    const [selectedRow, setSelectedRow] = useState(0);

    const handleRowClick = (index) => {
        setSelectedRow(index);
    };


    //set the selected row based on the path in the url
    useEffect(() => {
        buttons.forEach((button, index) => {
            if (button.url === path) {
                setSelectedRow(index);
            }
        })
    }
        , [path])

    return userLoading === false ? (
        <div className='navbar'>
            <div className="navTop">
                <div className="navLogo">
                    <img src={DevzeryIcon} alt="logo" />
                </div>
                <Typography sx={{ marginTop: "48px" }} variant='h6'>Devzery</Typography>
                <div className="accMenu">
                    {/* <AccountMenu /> */}
                </div>
            </div>
            <div className="navBtns">
                {buttons.map((button, index) => (
                    <div 
                    className={`nav-btn${selectedRow==index?'-selected':''}`}
                    onClick={()=>{handleRowClick(index)}}
                    >
                        <NavBtn
                        name={button.name}
                        icon={button.icon}
                        url={button.url}
                        isSelected={selectedRow==index}
                    />
                    </div>
                ))}
            </div>
            <div className="navBottom">
                <NavBtn
                    name={'Logout'}
                    icon={LogoutIcon}
                    url={'/logout'}
                />
            </div>
        </div>
    ) : null
}

export default Navbar
