import React from 'react'
import './TypeBtn.css'
import { Typography } from '@mui/material'


/*
TypeBtn component that renders the type button for tables.
It displays the number of tests for a particular type.
Depending on the type, the button is styled differently.

*/

const TypeBtn = ({ type, tests }) => {

    let types = ""
    switch (type) {
        case "assertions":
            types = "assertions"
            break;
        case "pass":
            types = "pass"
            break;
        case "fail":
            types = "fail"
            break;
    }

    return (
        <div className={`type-${types}`}>
            <Typography variant='h5'
            >
                {tests}
            </Typography>
        </div>
    )
}

export default TypeBtn
