import { useState } from "react";

const AddAPIHeaders = ({headers, setHeaders}) => {
    const [headerKey, setHeaderKey] = useState(null);
    const [headerCount, setHeaderCount] = useState(4);

    const renderHeaders = () => {
        const rows = [];
        for (let i = 0; i < headerCount; i++) {
            rows.push(<div className="flex">
                <input
                    className="w-full px-3 py-2 border border-r-1 border-b-1 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]"
                    id="outlined-required"
                    onChange={(e) => {
                        setHeaderKey(e.target.value);
                    }}
                />
                <input
                    className="w-full px-3 py-2 border border-b-1 border-r-0 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]"
                    id="outlined-required"
                    onChange={(e) => {
                        setHeaders({
                            ...headers,
                            [headerKey]: e.target.value,
                        });
                    }}
                />
            </div>)
        }
        return rows
    }

    return  <div className="flex flex-col">
    <div className="rounded-md border border-1 border-[#2C2E33] mt-2">
    <div className="flex">
      <div className="w-full px-3 py-3 border border-r-1 border-b-1 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]">
        Header name
      </div>
      <div className="w-full px-3 py-3 border border-b-1 border-r-0 border-t-0 border-l-0 border-[#2C2E33] bg-[#0F1011]">
        Value
      </div>
    </div>
    {renderHeaders()}
  </div>
    <div
      className="py-2 px-3 bg-[#2C2E33] text-white self-end mt-2 cursor-pointer rounded-md"
      onClick={() => setHeaderCount(headerCount + 1)}
    >Add Headers</div>
    </div>
}

export default AddAPIHeaders;