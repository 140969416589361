import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import store  from './store'; 
import { refreshAccessToken } from './Actions/userActions'; 


// Add a request interceptor to check for the access token before making a request to the server
/*

The axios.interceptors.request.use() method is used to intercept the request before it is sent to the server.
The method takes two arguments, the first argument is a function that is executed before the request is sent to the server.
The second argument is a function that is executed when there is an error in the request.
The function takes the config object as an argument, which contains the request configuration.
The function returns the config object after adding the Authorization header to it.
The Authorization header contains the access token.
The access token is retrieved from the local storage.
The access token is decoded using the jwtDecode() method.
The decoded token contains the expiration time of the token.
The current time is calculated using the Date.now() method.
If the expiration time of the token is less than the current time, the access token is refreshed.
The refreshAccessToken() method is called to refresh the access token.
The access token is added to the Authorization header.
The config object is returned.

*/
axios.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        try {
          accessToken = await store.dispatch(refreshAccessToken());
        } catch (error) {
            console.log('Token refresh failed:', error);
            window.location.href = '/login';
        }
      }

      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
