import CircularProgress from '@mui/material/CircularProgress'
import ThirdList from '../../../../icons/third-list.svg'

const Loader = ({ type }) => {
    console.log("Loader", type)
    return <div className="w-full flex flex-col items-center justify-center p-24 gap-8">
        {type == "Verifying APIs" ? <div className='w-1/5'><img className='w-full' src={ThirdList} alt="ThirdList" /> </div>: null}
    <div className='flex items-center gap-4'>
        <span className='text-[#E45799]'>
            <CircularProgress size={24} color="inherit" />
        </span>
        {type}
    </div>
    </div>
}

export default Loader;