import React from "react";
import DevzeryIcon from "../../icons/devzery-icon.svg";
import "./WelcomePage.css";
import { Link } from "react-router-dom";
/*

WelcomePage component that renders the welcome page of the application.
The WelcomePage component uses the DevzeryIcon component to display the Devzery logo.
The WelcomePage component contains a title, a paragraph, and a button to get started.

*/
export const WelcomePage = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-[#080808] text-white">
      <img src={DevzeryIcon} />
      <div className="mt-8 mb-4 text-[40px] font-semibold">Welcome to Devzery</div>

      <p className="text-[#DCDCDC] text-center">
        Experience firsthand how Devzery&#39;s AI-powered API regression testing
        platform
        <br />
        streamlines testing and speeds up your development cycle.
      </p>
      <Link to="/login">
        <div className="w-[30vw] bg-[#D9509B] border border-1 border-[#FF86BE] rounded-md py-3 text-center mt-12">
          Get Started
        </div>
      </Link>

      {/* <DevzeryIcon className="devzery-icon-instance" /> */}
    </div>
  );
};
