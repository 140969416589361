
import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useDispatch } from 'react-redux'
import './KeyDropdown.css'
import { Dialog } from '@mui/material'
import DeleteIcon from '../../../../icons/delete-icon.svg'
import RefreshIcon from '../../../../icons/refresh.svg'


/*

Tooltip component that renders the tooltip for the key dropdown.
The Tooltip component contains the key dropdown icon.
The key dropdown icon is used to display the key dropdown menu.
The key dropdown menu contains options to refresh and delete the key.

*/
const ITEM_HEIGHT = 48;
const KeyDropdown = ({ id, prodId, editDetails, bug }) => {

    const [openDialog, setOpenDialog] = useState(false) // Use the openDialog state to determine if the dialog is open

    const [anchorEl, setAnchorEl] = React.useState(null); // Use the anchorEl state to determine the anchor element

    const open = Boolean(anchorEl); // To determine if the menu is open

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }


    return (
        <div className='key-dropdown'>

            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ color: "white", marginTop: "-1vw", marginLeft: "-1vw" }}
            >
                <IoEllipsisHorizontalSharp />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '15ch',
                        color: "white",
                        opacity: "1",
                        backgroundColor: "#21222d",
                        borderRadius: "4px"
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >

                <MenuItem>
                    <img className='menuImg' src={RefreshIcon}></img>
                    Refresh

                </MenuItem>
                <MenuItem>
                    <img className='menuImg' src={DeleteIcon}></img>
                    Delete
                </MenuItem>

            </Menu>
            <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{
                style: {
                    minHeight: "45vh",
                    minWidth: '40vw',
                    backgroundColor: '#191a23',
                    color: '#fff',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    border: '2px solid #fff'
                }
            }}>

            </Dialog>
        </div>
    )
}

export default KeyDropdown