import React from 'react'
import './BugCoverage.css'
import PieChart from '../../icons/pie-chart.png'
import { Typography } from '@mui/material'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import TestStatus from '../EndpointCoverage/TestStatus/TestStatus';

Chart.register(ArcElement, Tooltip, Legend, Title); // Create a chart with the necessary elements

/*

The BugCoverage component renders the bug coverage statistics .
It displays the number of open and closed bugs in a doughnut chart.
The component uses the Doughnut component from react-chartjs-2 to render the chart.
The component also displays the number of open and closed bugs.
The component takes in the bugs prop to render the bug coverage statistics.

*/
const BugCoverage = ({ bugs }) => {

    const bugsNull = bugs != null ? false : true;

    // Data for the chart
    const data = {
        labels: ['Open', 'Closed'],
        datasets: [{
            data: [360, 40],
            backgroundColor: [
                'rgba(242,123,179,255)',
                'rgba(151,167,255,255)',
            ],
            borderColor: [
                'rgba(242,123,179,255)',
                'rgba(151,167,255,255)',
            ],
            hoverOffset: 4,
        }],
        radius: 50,

    };

    // Chart design options
    const chartOptions = data != null ? {
        cutout: '70%',
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = data.labels[context.dataIndex] || '';
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label}: ${percentage}%`;
                    }
                }
            },
            legend: {
                display: false, // Hides the labels on top of the chart
            },
        },
        aspectRatio: 3,
        elements: {
            arc: {
                hoverRadius: 0, // Adjust this value to control the size of the label square when hovering
                borderWidth: 0.01, // Removes the border of the label square
            }
        }
    } : null;


    return (
        <div className={`bugCoverage${bugsNull ? '-empty' : ""}`}>
            <Typography variant='h5' className='bugCoverage-title'>
                Bug Coverage
            </Typography>
            {bugs != null ? <div className='httpChart'>
                <Doughnut
                    data={data}
                    options={chartOptions}
                />

            </div> :
                <>
                    <img src={PieChart} alt='Pie Chart' className='httpCoverage-image' />
                    <Typography variant='h6'>
                        Whoops! No data<br></br> available at the moment.
                    </Typography>
                </>
            }
            {bugs != null ? <div className='bugs-open-closed'>
                <TestStatus
                    isBugs={true}
                    status={"Open"}
                    val={bugs.open}
                />
                <TestStatus
                    isBugs={true}
                    status={"Closed"}
                    val={bugs.closed}
                />
            </div> : null}
        </div>
    )
}

export default BugCoverage
