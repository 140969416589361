import React from 'react'
import './Dependency.css'
import { Typography } from '@mui/material'

/*

The Depencency component renders the dependencies on the individual test case page.

*/
const Dependency = ({title}) => {
    // Use the title prop to display the title within the p tag
    return (
        <div className='dependency-container'>
            <p>{title}</p> 
        </div>
    )
}

export default Dependency
