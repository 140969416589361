import { PiLink, PiArrowLeftBold } from "react-icons/pi";

const SDKCard = ({image, lang, docsUrl, githubUrl}) => {
    return <div className="bg-[#17181A] border border-1 border-[#2E3138] p-3 rounded-md grid grid-cols-3">
    <div className="col-span-1 mr-3 p-4"><img className="w-full" src={image} alt="" /></div>
    <div className="col-span-2 flex flex-col justify-between">
        <div className="font-semibold capitalize">{lang} SDK</div>
        <div className="flex mt-2">
            <a href={docsUrl} target="_blank">
                <div className="flex items-center bg-[#26282F] mr-3 border border-1 border-[#2C2E33] px-3 py-1 rounded-lg">Docs <PiLink className="ml-2" /></div>
            </a>
            <a href={githubUrl} target="_blank">
                <div className="flex items-center bg-[#26282F] border border-1 border-[#2C2E33] px-3 py-1 rounded-lg">Github <PiLink className="ml-2" /></div>
            </a>
        </div>
    </div>
</div>
}

export default SDKCard;