import React, { useState, useEffect } from 'react';
import './App.css';
import CustomButton from './components/CustomButton/CustomButton';
import { SignIn } from './components/SignIn/SignIn';
import { WelcomePage } from './components/WelcomePage/WelcomePage';
import { ResetPassword } from './components/ResetPassword/ResetPassword';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TestSuite from './components/TestSuite/TestSuite';
import { GoogleOAuthProvider } from '@react-oauth/google'
import Navbar from './components/Navbar/Navbar'
import AddTC from './components/AddTC/AddTC';
import IndividualTest from './components/IndividualTest/IndividualTest';
import IndividualAPI from './components/IndividualAPI/IndividualAPI';
import BugTracker from './components/BugTracker/BugTracker';
import IndividualBug from './components/IndividualBug/IndividualBug';
import APIOverview from './components/APIOverview/APIOverview';
import { useDispatch, useSelector } from 'react-redux';
import Integrations from './components/Integrations/Integrations';
import SettingsComponent from './components/SettingsComponent/SettingsComponent';
import { loginUserSuccess, validateGoogleToken, googleSSO } from './Actions/userActions';
import ChatBot from './components/ChatBot/ChatBot';
import OrgDetails from './components/orgDetails/orgDetails';
import IntegrateSDK from './components/IntegrateSDK/IntegrateSDK';
import NotFound from './components/NotFound/NotFound';

/*

Main App component that renders the entire application.
The App component uses the react-router-dom library to handle routing in the application.
The App component uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
The App component uses the useEffect hook to check if the user is already logged in.
The App component renders different components based on the user's authentication status.

*/
function App() {
  const dispatch = useDispatch();
  const { loading, user } = useSelector(state => state.user); // user is the state in the reducer
  const [verificationCompleted, setVerficationCompleted] = useState(false);

  const isNew = JSON.parse(localStorage.getItem('isNew'));
  // Check if the user is already logged in, if so, set the user state
  const isAuthenticated = localStorage.getItem('isAuthenticated') || sessionStorage.getItem('isAuthenticated');
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
    const googleToken = localStorage.getItem('token');

    if(accessToken && !isAuthenticated){
      localStorage.clear();
      sessionStorage.clear();
    } else if (accessToken && user && isAuthenticated) {
      dispatch(loginUserSuccess({ user, accessToken }));
    } else if (googleToken) {
      const currentTime = new Date().getTime();
      const expirationTime = parseInt(localStorage.getItem('tokenExpirationTime'));

      if (currentTime <= expirationTime) {
        dispatch(googleSSO(googleToken));
      } else {
        // Token expired, remove it
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpirationTime');
      }
    }

  }, [dispatch]);

  return (
    <div className="App">

      <GoogleOAuthProvider clientId="817077646276-uak1j9jq5ud8cu4qnl892kse1p80pnvg.apps.googleusercontent.com">

        <Router>

          {/* {isAuthenticated && !isNew? <Navbar /> : null} */}
          {/* <Navbar/> */}
          <Routes>
            <Route path="/onboarding" element={<WelcomePage />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/" element={isAuthenticated && !isNew ? <TestSuite /> : <SignIn />} />
            <Route path="/login" element={isAuthenticated && !isNew ? <TestSuite /> : <SignIn />} />
            <Route path="/org-details" element={isAuthenticated && isNew && user ? <OrgDetails /> : <SignIn />} />
            <Route path="/integrate-sdk" element={isAuthenticated && isNew && user && user.orgDetails ? <IntegrateSDK /> : <SignIn />} />
            {/* <Route path="/org-details" element={isAuthenticated && isNew ? <OrgDetails /> : <SignIn />} /> */}
            <Route path="/chat" element={<ChatBot setVerficationCompleted= {setVerficationCompleted} />} />
            <Route path="/test-suite" element={isAuthenticated? <TestSuite /> : <SignIn />} />
            <Route path="/add-test-case" element={isAuthenticated ? <AddTC /> : <SignIn />} />
            <Route path='/test/:id' element={isAuthenticated ? <IndividualTest /> : <SignIn />} />
            <Route path='/api/:id' element={isAuthenticated ? <IndividualAPI /> : <SignIn />} />
            <Route path='/bug-tracker' element={isAuthenticated ? <BugTracker /> : <SignIn />} />
            <Route path='/bug/:id' element={isAuthenticated ? <IndividualBug /> : <SignIn />} />
            <Route path='/api-overview' element={isAuthenticated ? <APIOverview /> : <SignIn />} />
            <Route path='/integrations' element={isAuthenticated ? <Integrations /> : <SignIn />} />
            <Route path="/settings" element={isAuthenticated ? <SettingsComponent /> : <SignIn />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
