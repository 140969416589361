import { Dialog, Alert, Snackbar, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { PiUploadSimple, PiPlus } from "react-icons/pi";
import Left from "../../../icons/chevron-left.svg";
import Right from "../../../icons/chevron-right.svg";
import APICard from "./APICard/APICard";
import {
  addNamedApi,
  getAllAPIs,
  getUserApis,
  uploadPRD,
  deleteNamedUserApis,
  getResponseBody,
} from "../../../Actions/tcActions";
import { requestNames } from "../../../Actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader/Loader";
import JSONPretty from 'react-json-pretty';
import AddAPIHeaders from "./AddAPIHeaders/AddAPIHeaders";

const CollectAPI = ({ setPageStatus }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [addApiModalOpen, setAddApiModalOpen] = useState(false);
  const [productDescription, setProductDescription] = useState("");
  const [apiName, setApiName] = useState("");
  const [apiMethod, setApiMethod] = useState("post");
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [serverName, setServerName] = useState("");
  const [headers, setHeaders] = useState({});
  const [body, setBody] = useState(null);
  const [prdFile, setPrdFile] = useState(null);
  const [loadingType, setLoadingType] = useState("Uploading file");
  const [unSelectedApis, setUnSelectedApis] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { loading, responseBody, message, error } = useSelector((state) => state.apis);
  const { loading: sequenceLoading, apiNames, seqError } = useSelector((state) => state.sequence);
  const {
    loading: userLoading,
    user,
    userError,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleAddDetails = () => {
    setLoadingType("Collecting Unique APIs");
    dispatch(getUserApis(user?._id));
    setModalOpen(false);
  };

  let totalApis = [];
  if (apiNames) {
    totalApis = apiNames
  }

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalApis?.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = totalApis?.slice(startIdx, startIdx + itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleAddApi = (e) => {
    e.preventDefault();
    setLoadingType("Adding API")
    dispatch(addNamedApi({ apiName, apiMethod, apiEndpoint, serverName, headers, body, responseBody }))
    .then(() => {
      // Once the first dispatch is done, dispatch getUserApis
      dispatch(getUserApis(user?._id));
    })
    .catch((error) => {
      setAlertSeverity("error");
      setAlertMessage("Error adding API");
      setSnackbarOpen(true);
    });
    setAddApiModalOpen(false);
  };

  useEffect(() => {
    if (error || userError) {
      console.log("error", error);
      setAlertSeverity("error");
      setAlertMessage(error);
      setSnackbarOpen(true);
      dispatch({
        type: "clearError"
      });
    }
  }, [error, userError, loading]);

  useEffect(() => {
    if (message) {
      console.log("message", message);
      setAlertSeverity("success");
      setAlertMessage(message);
      setSnackbarOpen(true);
    }
  }, [message]);

  const handleFileUpload = (e) => {
    setPrdFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(uploadPRD(formData));
  };

  const handleProceed = () => {
    setPageStatus({ keyIntegrated: false, apiCollected: true, verificationCompleted: false });
    let copy = [...totalApis];
    if (unSelectedApis.length > 0) {
      copy.forEach((api, index) => {
        if(unSelectedApis.includes(api)) {
          copy.splice(copy[index], 1);
        }
      })
      dispatch(deleteNamedUserApis(copy));
    } else {
      dispatch(deleteNamedUserApis(totalApis));
    }
  };

  return (loading || sequenceLoading) ? (
    <Loader type={loadingType} />
  ) : apiNames ? (
    <div className="px-16 py-10 w-full flex flex-col items-center">
      <div className="flex self-end">
        <div
          onClick={() => setAddApiModalOpen(true)}
          className="cursor-pointer text-[#E7E7E7] bg-[#D9509B] border border-1 border-[#FF86BE] rounded min-w-fit mr-2 px-4 py-1"
        >
          Add API
        </div>
        <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] rounded min-w-fit px-4 py-1">
          Need Help?
        </div>
      </div>
      <div className="my-8">
        Please unselect APIs in the list that require more information before
        proceeding.
      </div>
      <div className="w-[70vw]">
        <div className="border border-1 border-[#3E4045] rounded-md w-full">
          <div className="flex justify-between p-4 border border-b-1 border-x-0 border-t-0 border-[#3E4045]">
            <div className="">API List</div>
            <div>
              Number of APIs captured{" "}
              <span className="ml-3 px-5 py-1 border border-1 border-[#F37BB3] rounded-md text-sm">
                {totalApis?.length}
              </span>
            </div>
          </div>
          <div className="grid grid-rows-5 grid-cols-2">
            {currentApis.map((api, index) => (
              <APICard
                api={api}
                index={index}
                unSelectedApis={unSelectedApis}
                currentPage={currentPage}
              />
            ))}
          </div>
        </div>
        <div className="flex items-center self-end mt-4">
          <div className="flex text-[#EFEFEF] mr-2">
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-[#B0B0B0] text-sm mr-3 px-3 py-1"
              onClick={handlePrevPage}
            >
              <img src={Left} className="mr-2" /> Prev
            </div>
            <div className={"grid gap-2 mr-3 " + ((startIdx + itemsPerPage) >= totalApis?.length ? "grid-cols-2" : "grid-cols-3")}>
              {(startIdx + itemsPerPage) >= totalApis?.length ? ( <>
                <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                  {currentPage - 1}
                </div>
                <div className="border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                  {currentPage}
                </div>
              </>) : currentPage > 1 ? (
                <>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage - 1}
                  </div>
                  <div className="border border-1 border-[#F257A0] p-1 w-[34px] bg-[#F257A0] text-center rounded-md">
                    {currentPage}
                  </div>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 1}
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-[#F257A0] p-1 w-[34px] text-center rounded-md">
                    {currentPage}
                  </div>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 1}
                  </div>
                  <div className="border border-1 border-[#2C2E33] p-1 w-[34px] text-center rounded-md text-[#B0B0B0]">
                    {currentPage + 2}
                  </div>
                </>
              )}
            </div>
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#2C2E33] text-sm px-3 py-1 mr-4"
              onClick={handleNextPage}
            >
              Next <img className="ml-2" src={Right} />
            </div>
          </div>
          <div className="text-[#EFEFEF] text-sm font-light">
            {" "}
            {startIdx + 1 === totalApis?.length
              ? startIdx + 1
              : (startIdx + itemsPerPage) >
                totalApis?.length
              ? totalApis?.length
              : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "}
            of {totalApis?.length} Results
          </div>
        </div>
        {/* <div className="flex flex-col self-center items-center mt-8">
          <div>Confirm if all APIs are collected</div>
          <div className="flex mt-3">
            <div onClick={() => setApiCollected(true)} className="bg-[#26282F] border border-1 border-[#35383E] rounded-md px-6 py-1 mr-2 cursor-pointer">Yes</div>
            <div className="bg-[#30333C] border border-1 border-[#484B52] rounded-md px-6 py-1">No</div>
          </div>
        </div> */}
      </div>
      <div
        onClick={handleProceed}
        className="cursor-pointer w-1/3 py-2.5 mt-8 rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] text-center self-center"
      >
        Proceed to API verification
      </div>
      <Dialog
        maxWidth="85vw"
        open={addApiModalOpen}
        onClose={() => setAddApiModalOpen(false)}
        PaperProps={{
          style: {
            minHeightheight: "fit",
            width: "85vw",
            backgroundColor: "#17171A",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #2C2E33",
          },
        }}
      >
        <div className="px-12 py-8 w-full">
          <div className="flex justify-between items-center">
            <div className="font-semibold">Add API To The List</div>
            <div
              onClick={() => setAddApiModalOpen(false)}
              className="origin-center rotate-45 cursor-pointer"
            >
              <PiPlus />
            </div>
          </div>
          <form onSubmit={handleAddApi}>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="grid grid-cols-5 gap-4 items-start">
                <div className="col-span-3">
                  <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                    API Name <span className="text-[#F37BB3]">*</span>
                  </label>
                  <br></br>
                  <input
                    className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                    value={apiName}
                    required
                    placeholder="Enter your API name"
                    id="outlined-required"
                    onChange={(e) => {
                      setApiName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                    HTTP Method <span className="text-[#F37BB3]">*</span>
                  </label>
                  <br></br>
                  <select
                    defaultValue="post"
                    onChange={(e) => setApiMethod(e.target.value)}
                    name="api-method-select"
                    className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                    id=""
                  >
                    <option
                      value="post"
                      className="text-[#FCD34D] bg-green-500"
                    >
                      POST
                    </option>
                    <option value="get" className="text-[#FCD34D]">
                      GET
                    </option>
                    <option value="put" className="text-[#FCD34D]">
                      PUT
                    </option>
                    <option value="delete" className="text-[#FCD34D]">
                      DELETE
                    </option>
                  </select>
                </div>
                <div className="col-span-5">
                  <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                    API Endpoint <span className="text-[#F37BB3]">*</span>
                  </label>
                  <br></br>
                  <input
                    className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                    value={apiEndpoint}
                    required
                    placeholder="/v1/test"
                    id="outlined-required"
                    onChange={(e) => {
                      setApiEndpoint(e.target.value);
                    }}
                  />
                </div>
                <div className="col-span-5">
                  <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                    Server Name <span className="text-[#F37BB3]">*</span>
                  </label>
                  <br></br>
                  <input
                    className="w-full px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                    value={serverName}
                    required
                    placeholder="https://servername.app"
                    id="outlined-required"
                    onChange={(e) => {
                      setServerName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-span-5">
                  <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                    Headers <span className="text-[#F37BB3]">*</span>
                  </label>
                  <br></br>
                  <AddAPIHeaders headers={headers} setHeaders={setHeaders} />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="">
                  <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                    Request Body <span className="text-[#F37BB3]">*</span>
                  </label>
                  <br></br>
                  <textarea
                    className="w-full min-h-[25vh] max-h-[30vh] overflow-scroll px-3 py-3 border border-1 border-[#2C2E33] rounded-md bg-[#0F1011] mt-2"
                    value={body}
                    placeholder="{'key': 'value'}"
                    id="outlined-required"
                    onChange={(e) => {
                      setBody(e.target.value);
                    }}
                  />
                </div>
                <div className="">
                  <label htmlFor="outlined-required" className="text-[#B0B0B0]">
                    Response <span className="text-[#F37BB3]">*</span>
                  </label>
                  <br></br>
                  <div className="max-w-[40vw] min-h-[25vh] max-h-[30vh] px-3 py-3 border border-1 border-[#2C2E33] overflow-scroll rounded-md bg-[#0F1011] text-[#B0B0B0] mt-2">
                    {responseBody ? <JSONPretty
                        style={{ overflowY: 'scroll', height: "100%" }}
                        id="json-pretty"
                        themeClassName='text-sm'
                        keyStyle='color: #E45799;'
                        data={JSON.stringify(responseBody ?? {"message": "hi"})}>
                    </JSONPretty> : "Test the API to see the response"}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between text-sm items-center mt-6">
            <Button
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "#D9509B",
                    border: "1px solid #FF86BE",
                    padding: "6px 16px",
                    ":hover": {
                      backgroundColor: "#D9509B",
                    },
                    color: "white",
                    textTransform: "unset",
                    fontWeight: "400",
                  }}
                  onClick={() => {dispatch(getResponseBody({ body, headers, apiEndpoint, apiMethod, serverName })); setLoadingType("Testing API")}}
                  className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
                >
                  Test the API
                </Button>
              <div className="flex text-sm">
                <div
                  onClick={() => setAddApiModalOpen(false)}
                  className="cursor-pointer mr-3 rounded-md bg-[#2D2F37] border border-1 border-[#35383E] px-4 py-1.5"
                >
                  Cancel
                </div>
                <Button
                  sx={{
                    "&.Mui-disabled": {
                      background: "#222222",
                      color: "white",
                      border: "1px solid #222222",
                    },
                    borderRadius: "6px",
                    backgroundColor: "#D9509B",
                    border: "1px solid #FF86BE",
                    padding: "6px 16px",
                    ":hover": {
                    backgroundColor: "#D9509B",
                    },
                    color: "white",
                    textTransform: "unset",
                    fontWeight: "400",
                  }}
                  type="submit"
                  {...(responseBody ? { disabled: false } : { disabled: true })}
                  className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
                >
                  Add Details
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  ) : (
    <Dialog
      open={modalOpen}
      PaperProps={{
        style: {
          minHeightheight: "fit",
          width: "70vw",
          backgroundColor: "#17171A",
          color: "#fff",
          boxShadow: "none",
          borderRadius: "12px",
          border: "1px solid #2C2E33",
        },
      }}
    >
      <div className="p-8 w-full">
        <div className="flex justify-between items-center">
          <div className="font-semibold flex items-center">
            Provide Your Application Details{" "}
            <span className="text-[#E7E7E7] font-normal ml-3 text-sm">
              (optional)
            </span>
          </div>
          <div
            onClick={() => setModalOpen(false)}
            className="origin-center rotate-45 cursor-pointer"
          >
            <PiPlus />
          </div>
        </div>
        <div className="text-[#B0B0B0] text-sm mt-1">
          Our AI agent will use this information to generate context-rich test
          cases tailored to your specific API needs.
        </div>
        <textarea
          onChange={(e) => {setProductDescription(e.target.value)}}
          placeholder="Enter details about your product here"
          className="rounded-md w-full bg-[#0F1011] border border-1 border-[#2C2E33] text-[#595A5C] text-sm p-4 mt-4"
        />
        <div className="my-6 flex items-center justify-start">
          {/* <div className="or-line"></div> */}
          <div className="w-[48%] h-[1px] bg-[#2C2E33]" />
          <div className="px-2">or</div>
          <div className="w-[48%] h-[1px] bg-[#2C2E33]" />
        </div>
        <div className="cursor-pointer rounded-md bg-[#26282F] border border-1 border-[#35383E] flex items-center px-3 py-1.5 text-sm max-w-fit">
          <span className="mr-2">
            <PiUploadSimple />
          </span>
          <input
            className=" absolute left-[70px] opacity-0 w-[120px] cursor-pointer z-20"
            onChange={(e) => handleFileUpload(e)}
            type="file"
            accept=".pdf, .doc, .docx, .txt"
            placeholder="Upload PRD File"
          />
          {prdFile ? (
            <div className="">{prdFile.name}</div>
          ) : (
            <div className="">Upload PRD File</div>
          )}
        </div>
      </div>
      <div className="p-4 border border-t-1 border-[#2C2E33] flex justify-end text-sm">
        <div
          onClick={handleAddDetails}
          className="cursor-pointer mr-3 rounded-md bg-[#2D2F37] border border-1 border-[#35383E] px-4 py-1.5"
        >
          Skip
        </div>
        <Button
          sx={{
            "&.Mui-disabled": {
              background: "#222222",
              color: "white",
              border: "1px solid #222222",
            },
            borderRadius: "6px",
            backgroundColor: "#D9509B",
            border: "1px solid #FF86BE",
            padding: "6px 16px",
            ":hover": {
              backgroundColor: "#D9509B",
            },
            color: "white",
            textTransform: "unset",
            fontWeight: "400",
          }}
          onClick={handleAddDetails}
          {...(prdFile || productDescription ? { disabled: false } : { disabled: true })}
          className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
        >
          Add Details
        </Button>
      </div>
    </Dialog>
  );
};

export default CollectAPI;
