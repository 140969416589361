import React from 'react'
import './StatusBtn.css'
import { Typography } from '@mui/material'

/*

StatusBtn component that renders the status button for tables.
It displays the status of the test case, API, or bug.
Depending on the status, the button is styled differently.

*/
const StatusBtn = ({ status, type }) => {
    let color;
    switch (status) {
        case 'Failed':
            status = 'Failed'
            break;
        case 'Pass':
            status = 'Pass'
            break;
        case 'Pending':
            status = 'Pending'
            break;
        case 'Open':
            status = 'Open'
            color="rgba(243, 123, 179, 0.8)"
            break;
        case 'Close':
            status = 'Close'
            color="rgba(150, 167, 255, 0.5)"
            break;
    }
    return type == "TC" ? (
        <div className={status}>
            <Typography variant='h5'>
                {status}
            </Typography>
        </div>
    ) : type == "API" ? (
        <div className="api-status">
            <Typography variant='h6'>{status}</Typography>
        </div>
    ) : (
        <div style={{backgroundColor:color}} className="bug-status">
            <Typography variant='h6'>{status}</Typography>
        </div>
    )
}

export default StatusBtn
