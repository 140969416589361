import React from 'react'
import './HTTPCoverage.css'
import PieChart from '../../icons/pie-chart.png'
import { Typography } from '@mui/material'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Method from './Method/Method';

Chart.register(ArcElement, Tooltip, Legend, Title); // Create a chart with the necessary elements

/*

The HTTPCoverage component renders the HTTP coverage statistics for the endpoint.
It displays the number of HTTP requests made in a doughnut chart.
It uses the Doughnut component from react-chartjs-2 to render the chart.
The component also displays the number of HTTP requests made.
The component takes in the http prop to render the HTTP coverage statistics.

*/
const HTTPCoverage = ({http}) => {

    const isNull = http == null;
    const get=http?.getCount
    const post=http?.postCount
    const put=http?.putCount
    const patch=http?.patchCount
    const del=http?.deleteCount

    // Data for the chart
    const data = {
        labels: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
        datasets: [{
            data: [get, post, put, patch, del],
            backgroundColor: [
                'rgba(73,222,128,255)',
                'rgba(253,211,76,255)',
                'rgba(68,165,255,255)',
                'rgba(187,118,255,255)',
                'rgba(248,113,113,255)',
            ],
            borderColor: [
                'rgba(73,222,128,255)',
                'rgba(253,211,76,255)',
                'rgba(68,165,255,255)',
                'rgba(187,118,255,255)',
                'rgba(248,113,113,255)',
            ],
            hoverOffset: 4,
        }],
        radius: 60,
    };

    // Chart design options
    const chartOptions =data!=null? {
        cutout: '75%',
        plugins: {
            tooltip: {
                enabled:true,
                callbacks: {
                    label: function (context) {
                        const label = data.labels[context.dataIndex] || '';
                        const value = context.formattedValue;
                        const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label}: ${percentage}%`;
                    }
                }
            },
            legend: {
                display: false, // Hides the labels on top of the chart
            },
        },
        aspectRatio: 2.5,
        elements: {
            arc: {
              hoverRadius: 0, // Adjust this value to control the size of the label square when hovering
              borderWidth: 0.01, // Removes the border of the label square
            }
          }
    }:null;

    return (
        <div className={`httpCoverage${isNull?'-empty':""}`}>

            <Typography variant='h5' className='httpCoverage-title'>
                HTTP Coverage
            </Typography>
            {http!=undefined ? <div className='httpChart'>
                <Doughnut
                    data={data}
                    options={chartOptions}
                />
                <div className="absolute top-[40%] font-light text-[#F37BB3] left-1/2 -translate-x-1/2 text-xs text-center">Total methods <div className='text-white font-regular'>{get+post+put+patch+del}</div></div>
            </div> :
                <>
                    <img src={PieChart} alt='Pie Chart' className='httpCoverage-image' />
                    <Typography variant='h6'>
                        Whoops! No data<br></br> available at the moment.
                    </Typography>
                </>
            }
          {http!=null? <div className='methodsContainer'>
           <Method
                method={"GET"}
                val={get}
            />
             <Method
                method={"POST"}
                val={post}
            />
             <Method
                method={"PUT"}
                val={put}
            />
             <Method
                method={"PATCH"}
                val={patch}
            />
             <Method
                method={"DELETE"}
                val={del}
            />
           </div>:null}
        </div>
    )
}

export default HTTPCoverage
