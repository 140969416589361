import React, { useEffect, useState, } from 'react'
import './IssuesTable.css'
import IssuesTableDropdown from './IssuesTableDropdown/IssuesTableDropdown'
import { Dialog, IconButton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import StatusBtn from './StatusBtn/StatusBtn'
import Calendar from '../../icons/Vector.svg'
import Clock from '../../icons/clock.svg'
import StopWatch from '../../icons/stopwatch.svg'
import Right from '../../icons/chevron-right.svg'
import Left from '../../icons/chevron-left.svg'
import SortBtnMenu from './SortBtnMenu/SortBtnMenu'
import tests from './test.json'
import { Link } from 'react-router-dom'
import CloseIcon from '../../icons/close.svg'
import { runAllTests } from '../../Actions/tcActions'
import { getKey } from '../../utils/data'


/*

IssuesTable component that renders the issues table for the test suite page.
The IssuesTable component displays the test cases in a table format.
The component uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
The component uses the StatusBtn component to display the status of the test cases.
The component uses the IssuesTableDropdown component to display the dropdown menu for the test cases.

It has 5 props: prodId, isBugs, isAPI, isTC, tests
The isBugs, isAPI, isTC props are used to determine the type of test cases to display.
The tests prop is used to display the test cases in the table.

*/
const IssuesTable = ({ prodId, isBugs, isAPI, isTC, tests }) => {

    const { user } = useSelector(state => state.user)
    const key = getKey()
    const { loading } = useSelector(state => state.testCases)

    //Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(tests?.length / itemsPerPage);
    const startIdx = (currentPage - 1) * itemsPerPage;
    const currentApis = tests?.slice(startIdx, startIdx + itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    }


    const dispatch = useDispatch();
    let type;
    if (isBugs) {
        type = 'Bug Report'
    } else if (isAPI) {
        type = 'API'
    } else if (isTC) {
        type = 'TC'
    }

    // State for sort and filter
    const [sortOpen, setSortOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);

    // State for run modal
    const [runModalOpen, setRunModalOpen] = useState(false)

    // State to update source and BaseURL
    const [source, setSource] = useState("")
    const [baseUrl, setBaseUrl] = useState("")

    const handleRunModalOpen = () => {
        setRunModalOpen(true)
    }

    const handleRunModalClose = () => {
        setRunModalOpen(false);
    }

    // Run all tests handler
    const runAllTestsHandler = async (e) => {
        try {
            const userId = user._id
            await dispatch(runAllTests(userId, key, baseUrl))
        }
        catch (error) {
            console.log(error)

        }
    }

    // Show or hide sort and filter menu
    const handleSortOpen = () => {
        setSortOpen(!sortOpen);
    }
    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen);
    }
    return (
        <div className='issueTableContainer'>

            <div className="flex justify-between items-center w-full border border-1 rounded-t-lg border-[#313248] border-b-0 p-4">
                <div>
                    {isBugs ? "Bug Report" : "Test Cases"}
                </div>
                <div className="flex items-center">
                    <input
                        placeholder='Search'
                        className='searchTC'
                    />
                    <button className='sortBtn' onClick={handleSortOpen}>
                        Sort
                    </button>
                    {
                        sortOpen && <SortBtnMenu />
                    }
                    <button className='filterBtn' onClick={handleFilterOpen}>
                        Filter
                    </button>
                    {
                        filterOpen && <SortBtnMenu />
                    }
                    {!isBugs && <button
                        onClick={handleRunModalOpen}
                        className={`runAllBtn${loading ? '-disabled' : ''}`}
                        disabled={loading}
                    >
                        Run All
                    </button>}
                </div>
            </div>
            <Dialog
                open={runModalOpen}
                onClose={handleRunModalClose}
                PaperProps={{
                    style: {
                        height: "40%",
                        width: "400px",
                        backgroundColor: '#21222D',
                        color: '#fff',
                        boxShadow: 'none',
                        borderRadius: '12px',
                        border: '1px solid #3D3D3D',
                        overflow: "hidden"
                    }
                }}>
                <div className='run-modal'>
                    <div className="close-run"
                        onClick={handleRunModalClose}
                    >
                        <img src={CloseIcon} />
                    </div>
                    <div className="add-ver">
                        <Typography>Add Version</Typography>
                    </div>
                    <div className="ver-input">
                        <input
                            placeholder='Enter the Base URL'
                            value={baseUrl}
                            onChange={(e) => { setBaseUrl(e.target.value) }}
                        />
                        {/* <input
                            placeholder='Enter the Source Name'
                            value={source}
                            onChange={(e) => { setSource(e.target.value) }}
                            style={{
                                marginTop: "30px"
                            }}
                        /> */}
                    </div>
                    <div className={`run-btn`}>
                        <button
                            className={`run-tests${loading ? '-disabled' : ''}`}
                            onClick={() => { runAllTestsHandler() }}
                            disabled={loading}
                        >Confirm And Run Tests</button>
                    </div>

                </div>
            </Dialog>
            <table className='w-full'>
                <tr className='colNames'>
                    <th><p>Test ID</p></th>
                    <th><p>Test Cases</p></th>
                    <th><p>Status</p></th>
                    {/* <th className='priorityTh'>Priority</th> */}
                    <th className='actionTh'><p>Action</p></th>
                </tr>
                {
                    currentApis && currentApis.map((test, index) => (
                        <tr key={index}>
                            <td className='testIdCol'>{
                                test?.tcNumber
                            }</td>
                            <td className='testNameCol'>
                                {test?._id ? <Link to={`/test/${test?._id}`}>
                                    {test?.name}
                                </Link> : test?.name}
                                <div>
                                    <div className="testDetails">
                                        <div className="date">
                                            <img src={Calendar} alt='calendar' />
                                            <p>{test?.date}</p>
                                        </div>
                                        <div className="time">
                                            <img src={Clock} alt='clock' />
                                            <p>{test?.time}</p>
                                        </div>
                                        <div className="duration">
                                            <img src={StopWatch} alt='stopwatch' />
                                            <p> {test?.duration}</p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='statusCol'>
                                {test?._id ? <StatusBtn
                                    type={type}
                                    status={test?.pass ? "Pass" : "Failed"}
                                /> : <StatusBtn
                                    type={type}
                                    status={"Pending"}
                                />}
                            </td>
                            {/* <td style={{ paddingLeft: '15px' }}
                                className={`priority-${bug.priority}`}>{bug.priority}</td> */}
                            <td className='actionsCol'>
                                <IssuesTableDropdown
                                test={test}
                                // editDetails={setOpenDialog}
                                // id={bug.id}
                                // bug={bug}
                                // prodId={prodId}
                                />
                            </td>
                        </tr>

                    ))
                }
            </table>
            <div className="btmRow">
                <div className="btmRow-right">
                    <div className="resultsPerPage">
                        <Typography variant='h6' sx={{ fontSize: "0.8vw" }}>Results per page</Typography>
                        <div className='numberOfResults'>
                            <p>10</p>
                        </div>
                    </div>
                    <div className="tableNaviation">
                        <div className="tnText">
                            <Typography variant='h6' sx={{ fontSize: "0.8vw" }}>{" "}
                                {startIdx + 1 === tests?.length
                                ? startIdx + 1
                                : (startIdx + itemsPerPage) >
                                    tests?.length
                                ? tests?.length
                                : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "} of {tests?.length}</Typography>
                        </div>
                        <div className="navigationBtns">
                            <button
                                onClick={handlePrevPage}
                            ><img src={Left} /></button>
                            <button
                                onClick={handleNextPage}
                            ><img src={Right} /></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default IssuesTable