import React from 'react'
import './IntegrationCard.css'
import DownloadIcon from '../../../icons/download.svg'

const IntegrationCard = ({icon,title}) => {
    return (
        <div className='integration-card'>
            <div className="integration-img">
                <img src={icon} />
            </div>
            <div className="integration-info">
                <div className="type-header">
                   <p>{title}</p>
                </div>
                <div className="integrate-btn">
                    <img src={DownloadIcon}/>
                    <p>Integrate</p>
                </div>
            </div>
        </div>
    )
}

export default IntegrationCard
