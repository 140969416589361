import React from 'react'
import './Workspace.css'
import { Typography } from '@mui/material'

/*

Workspace component that renders the workspace section of the settings page.
The Workspace component contains a dropdown to select the workspace and a button to delete the workspace.
It also contains a section to add new repositories.
It contains a dropdown to select the repository and branch and a button to add the repository.

*/

const Workspace = () => {
    return (
        <div className='workspace-container'>
            <div className="sel-del-workspace">
                <div className="sel-workspace">
                    <Typography variant='h6'>Workspace</Typography>
                    <select>
                        <option value="Select Workspace" disabled selected>Select Workspace</option>
                    </select>
                </div>
                <div className="del-workspace">
                    <button>Delete Workspace</button>
                </div>
            </div>
            <div className="repos">
                <div className="user-repos">
                    <p>Repositories</p>
                </div>
                <div className="add-new-repo">
                    <div className="new-repo-heading">
                        <p>Add New Repository</p>
                    </div>
                    <div className="new-repo-container">
                        <div className="new-repo">
                            <p>Add Repository</p>
                            <div className="sel-repo">
                                <select>
                                    <option value="Select Repository" disabled selected>Select Repository</option>
                                </select>
                            </div>
                        </div>
                        <div className="repo-branch">
                            <p>Select Branch</p>
                            <div className="sel-branch">
                                <select>
                                    <option value="Select Branch" disabled selected>Select Branch</option>
                                </select>
                            </div>
                        </div>
                        <button className='new-repo-btn'>Add</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Workspace
