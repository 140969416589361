import React from 'react'
import './Support.css'
import { Typography } from '@mui/material'

/*

Support component that renders the support section of the settings page.
It contains an email input field and a comments textarea field.
It also contains a send button to submit the support request.

*/

const Support = () => {
    return (
        <div className='support-container'>
            <div className="support-email">
                <div className="email-heading">
                    <Typography variant='h6'>
                        Email
                    </Typography>
                </div>
                <div className="email-text">
                    <input type="text" placeholder='Enter your email' />
                </div>
            </div>
            <div className="support-comments">
                <div className="comments-heading">
                    <Typography variant='h6'>
                        Comments
                    </Typography>
                </div>
                <div className="comments-text">
                    <textarea
                        placeholder='Enter your comments'
                    />
                </div>
            </div>
            <div className="support-send-btn">
                <button className='send-btn'>Send</button>
            </div>
        </div>
    )
}

export default Support
