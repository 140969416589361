import React from 'react'
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

/*
Loader component that renders a loader when the page is loading.
The loader is displayed as a backdrop with a circular progress indicator.
The loader component takes a loading prop to determine if the loader should be displayed.
The loader component uses the Backdrop and CircularProgress components from Material-UI.

*/
const Loader = ({ loading }) => {
    return (
        <Backdrop
            sx={{ color: '#E45799', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loader
