import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Typography } from "@mui/material";
import { getBaseURLs, addBaseURL } from "../../../Actions/tcActions";
import BaseUrl from "../BaseUrl/BaseUrl";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import PlusIcon from "../../../icons/plus-icon.svg";

const BaseUrlModal = ({ modalOpen, setModalOpen }) => {
  const [sources, setSources] = useState([]);
  const [sourceLength, setSourceLength] = useState(0);
  const dispatch = useDispatch();

  const { loading, baseUrls } = useSelector((state) => state.apis);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    dispatch(getBaseURLs());
  }, []);

  useEffect(() => {
    if (baseUrls && baseUrls.data) {
      setSources(baseUrls.data.urls);
      setSourceLength(baseUrls.data.urls.length);
    }
  }, [baseUrls]);

  const renderSources = () => {
    let arr = [];
    for (let i = 0; i < sourceLength; i++) {
      arr.push(
        <BaseUrl
          source={sources[i]}
          sources={sources}
          setSources={setSources}
        />
      );
    }
    return arr;
  };

  const handleAddBaseURL = () => {
    console.log("add base url");
    console.log(sources);
    try {
      dispatch(addBaseURL(sources));
      setModalOpen(false);
      setAlertSeverity("success");
      setAlertMessage("Base URL added successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.log(error);
      setAlertSeverity("error");
      setAlertMessage("Error adding base URL");
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <Dialog
        className="base-url-dialog"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{
          style: {
            height: "549px",
            minWidth: "50vw",
            maxWidth: "70vw",
            backgroundColor: "#21222D",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #3D3D3D",
          },
        }}
      >
        <div className="p-6">
          <Typography
            sx={{ fontFamily: "Roboto", fontWeight: "400" }}
            variant="h6"
          >
            Base URLs
          </Typography>
          {renderSources()}
          <div
            onClick={() => setSourceLength(sourceLength + 1)}
            className="plusBtn"
          >
            <img src={PlusIcon} alt="plus-icon" />
          </div>
          <div className="addCancel">
            <button onClick={handleAddBaseURL} className="addBtn">
              Add
            </button>
            <button
              className="cancelBtn"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BaseUrlModal;
