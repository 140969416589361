import React from 'react'
import './SettingsComponent.css'
import { Typography } from '@mui/material'
import Workspace from './Workspace/Workspace'
import Profile from './Profile/Profile'
import Notify from './Notify/Notify'
import Support from './Support/Support'
import Navbar from '../Navbar/Navbar'

/*

SettingsComponent component that renders the settings page of the application.
The SettingsComponent component contains buttons for Workspace, Profile, Notifications, and Support.
The SettingsComponent component uses the useState hook to manage the selected button state.
The SettingsComponent component renders the Workspace, Profile, Notifications, and Support components based on the selected button.
The Workspace, Profile, Notifications, and Support components are imported and rendered based on the selected button.


*/
const types = [
    {
        class:'workspaceBtn',
        name:'Workspace',
        component:Workspace
    },
    {
        class:'profileBtn',
        name:'Profile',
        component:Profile
    },
    {
        class:'notifyBtn',
        name:'Notifications',
        component:Notify
    },
    {
        class:'supportBtn',
        name:'Support',
        component:Support
    }
]
const SettingsComponent = () => {
    const [selectedIndex, setSelectedIndex] = React.useState(0) // Use the selected state to determine which button is selected
    return (
        <div className='settings-container'>
        <div className='settings-nav'><Navbar/></div>
        <div className='settings-page'>
            
            <div className="settings-top">
                <div className="select-workspace">
                    <select>
                        <option value="Select Workspace" disabled selected>Select Workspace</option>
                    </select>
                </div>
            </div>
            <div className="settings-middle">
                <div className="settings-status">
                    {types.map((type, index) => (
                      <button className={`${type.class}${selectedIndex==index?"-selected":" "}`}  
                        onClick={()=>setSelectedIndex(index)}
                        >
                            {type.name}
                        </button>
                    ))}
                </div>
            </div>
            <div className="settings-bottom">
                <div className="settings-tool">
                {types[selectedIndex].component && React.createElement(types[selectedIndex].component)}
                </div>
            </div>
        </div>
        </div>
    )
}

export default SettingsComponent
