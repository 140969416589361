import React,{useState,useEffect} from 'react'
import './EditIssueForm.css'
import { Typography } from '@mui/material'
import {useDispatch } from 'react-redux'

const EditIssueForm = ({id,issueName,issuePriority,issueStatus}) => {

    const [name,setName]=useState(issueName)
    const [priority,setPriority]=useState(issuePriority)
    const [status,setStatus]=useState(issueStatus)

    const handleSubmit=async()=>{
        const updatedBugData={
            testcasename:name,
            status:status,
            priority:priority
        }
    }

    return (
        <div className='editIssueContainer'>
            <Typography variant='h6'>Bug ID : {id}</Typography>
            <textarea
            className='editIssueTitle'
            value={name}
            onChange={(e)=>{setName(e.target.value)}}
            rows={3}
            />
            <div className="setPriorityStatus">
                <select style={{ backgroundColor: '#191a23', color: 'white' }} className='editPriority' value={priority} onChange={(e)=>{setPriority(e.target.value)}}>
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="High">High</option>
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="Medium">Medium</option>
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="Low">Low</option>
                </select>
                <select style={{ backgroundColor: '#191a23', color: 'white' }} className='editStatus' value={status} onChange={(e)=>{setStatus(e.target.value)}} >
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="New">New</option>
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="In Progress">In Progress</option>
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="Pending Retest">Pending Retest</option>
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="Reopened">Reopened</option>
                    <option style={{ backgroundColor: '#191a23', color: 'white' }} value="Closed">Closed</option>
                </select>
            </div>
            <button onClick={handleSubmit}>Save</button>
        </div>
    )
}

export default EditIssueForm
