import {createReducer } from '@reduxjs/toolkit';

// Initial state for the user reducer
const initialState = {
  loading: false,
  user: null,
  key:null,
  isAuthenticated: false,
  error: null,
  onboarding: false,
  isNew:null,
};

/*

The userReducer is created using the createReducer function from the redux toolkit.
The initial state for the user reducer is defined.
The userReducer handles actions related to user authentication, login, registration, and logout.
Each action has a corresponding case in the userReducer - request, success, and failure.
The userReducer handles actions related to user onboarding status.
The userReducer handles actions related to updating the user's password.
The userReducer handles actions related to setting the authentication status.
The userReducer handles actions related to setting the onboarding status.
The userReducer handles actions related to google token validation.
The userReducer handles actions related to signing out with Google.
The userReducer handles actions related to loading the user.

*/

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase('loginRequest', (state) => {
      state.loading = true;
    })
    .addCase('loginSuccess', (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.key=action.payload.key;
      state.isAuthenticated = true;
      state.isNew=action.payload.isNew;
      state.error = null;
    })
    .addCase('loginFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })
    //googleLoginRequest
    .addCase('googleLoginRequest', (state) => {
      state.loading = true;
    })
    .addCase('googleLoginSuccess', (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.error = null;
      state.isNew = action.payload.isNew;
    })
    .addCase('googleLoginFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })
    .addCase('githubLoginRequest', (state) => {
      state.loading = true;
    })
    .addCase('githubLoginSuccess', (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.isNew = action.payload.isNew;
      state.error = null;
    })
    .addCase('githubLoginFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })
    .addCase('getUserIdByEmailRequest', (state) => {
      state.loading = true;
    })
    .addCase('getUserIdByEmailSuccess', (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    })
    .addCase('getUserIdByEmailFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })
    //getUserByIdRequest
    .addCase('getUserByIdRequest', (state) => {
      state.loading = true;
    })
    .addCase('getUserByIdSuccess', (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    }
    )
    .addCase('getUserByIdFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    }
    )
    //updateUserOnboardRequest
    .addCase('updateUserOnboardRequest', (state) => {
      state.loading = true;
    })
    .addCase('updateUserOnboardSuccess', (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
    })
    .addCase('updateUserOnboardFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    })
    .addCase('VALIDATE_TOKEN_REQUEST', (state) => {
      state.loading = true;
    })
    .addCase('VALIDATE_TOKEN_SUCCESS', (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error=null;
    })
    .addCase('VALIDATE_TOKEN_FAILURE', (state, action) => {
      state.loading = false;
      state.error = action.payload;

    })
    .addCase('registerRequest', (state) => {
      state.loading = true;
    })
    .addCase('registerSuccess', (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error=null;
    })
    .addCase('registerFailure', (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    })
    .addCase('loadUserRequest', (state) => {
      state.loading = true;
    })
    .addCase('loadUserSuccess', (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    })
    .addCase('loadUserFailure', (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    })
    .addCase('logoutUserRequest', (state) => {
      state.loading = true;
    })
    .addCase('logoutUserSuccess', (state) => {
      state.loading = false;
      state.user = null;
      state.message = null;
      state.isAuthenticated = false;
      state.error=null
    })
    .addCase('logoutUserFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = true;
    })
    .addCase('updatePasswordRequest', (state) => {
      state.loading = true;
    })
    .addCase('updatePasswordSuccess', (state) => {
      state.loading = false;
    })
    .addCase('updatePasswordFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    //SIGN_OUT_WITH_GOOGLE_REQUEST
    .addCase('SIGN_OUT_WITH_GOOGLE_REQUEST', (state) => {
      state.loading = true;
    })
    .addCase('SIGN_OUT_WITH_GOOGLE_SUCCESS', (state) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
      state.error=null
    })
    .addCase('SIGN_OUT_WITH_GOOGLE_FAILURE', (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = true;
    })
    //SET_AUTHENTICATION_STATUS
    .addCase('SET_AUTHENTICATION_STATUS', (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
    })
    //SET_ONBOARDING_STATUS
    .addCase('SET_ONBOARDING_STATUS', (state, action) => {
      state.loading = false;
      state.onboarding = action.payload;
    })
    .addCase('googleTokenValidationRequest', (state) => {
      state.loading = true;
    })
    .addCase('googleTokenValidationSuccess', (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error=null;
    })
    .addCase('googleTokenValidationFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase('sendOrgDetailsRequest', (state) => {
      state.loading = true;
    })
    .addCase('sendOrgDetailsSuccess', (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.error = null;
    })
    .addCase('sendOrgDetailsFailure', (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase('onboardingComplete', (state, action) => {
      state.isNew = false;
    })
});


export default userReducer;
